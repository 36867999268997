<div class="trinka_cloud_wrap">
    <div id="particles-js"></div>
    <div class="cloud_top_banners">
        <!-- <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-9">
                    <div class="trinka_cloud_banner_text text-center">
                        <h2 class="text-center">We Value Your Sensitive Data, Just as You Do.</h2>
                        <p style="margin: 0 0 10px 0;">No AI Training. Full Data Control. No Data Storage.</p>
                        <button type="button" (click)="scroll(target)">Contact Us</button>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="trinka_cloud_banner_img">
                        <video class="vid-video" id="myVideo" playsinline autoplay loop muted oncanplay="this.play()" onloadedmetadata="this.muted = true">
                            <source src="../../../../assets/images/video/data-sensitive-video.mp4#t=0.1" type="video/mp4">
                            Your browser does not support the video element. Kindly update it to latest version.
                        </video>
                    </div>
                </div>
            </div>
        </div> -->
        <section class="mainTopBanner">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="topBannerWrapper">
                            <div class="top-banner-left">
                                <h1>Sensitive Data Plan</h1>
                                <h2>We Value Your Sensitive Data, Just as You Do.</h2>
                                <p>No AI Training. Full Data Control. No Data Storage.</p>
                                <div class="btn-group">
                                    <button  class="filled-btn" type="button" (click)="scroll(target)">Contact Us</button>
                                </div>
                            </div>
                            <div class="top-banner-right">
                                <iframe width="100%" height="310" src="https://www.youtube.com/embed/3wTToHVpBE0" title="LaTeX Proofreading | LaTeX Grammar Checker by Trinka AI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <video class="vid-video" id="myVideo" playsinline autoplay loop muted oncanplay="this.play()" onloadedmetadata="this.muted = true">
                                    <source src="../../../../assets/images/video/data-sensitive-video.mp4#t=0.1" type="video/mp4">
                                    Your browser does not support the video element. Kindly update it to latest version.
                                </video>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div class="brandLogo_sections">
        <div class="container">
            <div class="row">
                <div class="col-lg-2">
                    <h2>Trusted by Global Leaders</h2>
                </div>
                <div class="col-lg-10">
                    <div class="cover_wrapper">
                        <div id="client-logos-enterprise" class="owl-carousel">
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/alternet.png" alt="leaders">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/j-j.png" alt="leaders">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/PrincetonUniversity.png" alt="universities" width="180">
                                </div>
                            </div>
                            
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/novartis.png" alt="leaders">
                                </div>
                            </div>
                            

                            
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/scholastic.png" alt="leaders">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/cigna.png" alt="leaders">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/cambridge.png" alt="universities" width="180">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/sanchini.png" alt="leaders">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/merck.png" alt="leaders">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/evolution_editing.png" alt="evolution_editing">
                                </div>
                            </div>

                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/HarvardMedicalSchool.png" alt="universities" width="180">
                                </div>
                            </div>

                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/StanfordUniversity.png" alt="universities" width="180">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/HarperCollinsPublishers.png" alt="leaders">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/Caltech.png" alt="universities" width="180">
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <section class="section-pd onlyEnterprise">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Only for Enterprises</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-5">
                    <img src="../../../assets/images/only-enterprise.svg" loading="lazy" decoding="async" class="img-fluid" alt="enterpise">
                </div>
                <div class="col-lg-8 col-md-7">
                    <div class="trinka-title mb-0">
                        <p>Trinka's Sensitive Data Plan is made for enterprises which require the highest level of security and would not like their organizational data saved on our servers. Under this plan, the content processed for grammar checking on Trinka is not saved persistently, and once the results are processed, it automatically gets deleted from all our systems. Thus, your content is not used for any AI training to improve our systems, ensuring the utmost secrecy of your sensitive data.</p>
                        <p>This is a very unique offering amongst grammar checking companies, as AI companies rely on user data for improving their models/systems. Under Sensitive Data Plan, we prefer to give 100% control to our clients so that they are not worried about their trade secrets, intellectual property, or any other type of sensitive data that they may want to check.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="complianceBox">
                        <div class="row justify-content-center">
                            <div class="col-md-10 col-lg-11">
                                <div class="trinka-title mb-0 text-center">
                                    <h2>Compliances Covered</h2>
                                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                                    <p class="text-center">We adhere to the most stringent international information security and quality management standards.</p>
                                </div>
                            </div>
                        </div>
                        <ul>
                            <li>
                                <img src="../../../../assets/images/compliance-certificate1.svg" loading="lazy" decoding="async" alt="iso 270001:2013 information security certificate" />
                            </li>
                            <li>
                                <img src="../../../../assets/images/compliance-certificate2.svg" loading="lazy" decoding="async" alt="iso 9001:2015 quality certificate" />
                            </li>
                            <li>
                                <img src="../../../../assets/images/compliance-certificate3.svg" loading="lazy" decoding="async" alt="iso 17100:2015 translation service certificate" />
                            </li>
                            <li>
                                <img src="../../../../assets/images/compliance-certificate4.svg" loading="lazy" decoding="async" alt="ada certificate" />
                            </li>
                            <li>
                                <img src="../../../../assets/images/compliance-certificate5.svg" loading="lazy" decoding="async" alt="GDPR" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="cloud_key_features section-pd list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Key Features of Sensitive Data Plan</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>Enjoy a comprehensive set of features designed to address privacy concerns, enhance data security, and bolster trust. Here's what we offer:</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-none">
                        <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
                            <path d="M43.0625 39.75C42.1483 39.75 41.4062 40.4903 41.4062 41.4062V48.0312H11.5938V28.1562H16.5625H36.4375H41.4062C42.3205 28.1562 43.0625 27.4159 43.0625 26.5C43.0625 25.5841 42.3205 24.8438 41.4062 24.8438H38.0938V13.25C38.0938 6.85853 32.8931 1.65625 26.5 1.65625C20.1069 1.65625 14.9062 6.85853 14.9062 13.25V24.8438H9.9375C9.02325 24.8438 8.28125 25.5841 8.28125 26.5V49.6875C8.28125 50.6034 9.02325 51.3438 9.9375 51.3438H43.0625C43.9767 51.3438 44.7188 50.6034 44.7188 49.6875V41.4062C44.7188 40.4903 43.9767 39.75 43.0625 39.75ZM18.2188 13.25C18.2188 8.68372 21.9337 4.96875 26.5 4.96875C31.0663 4.96875 34.7812 8.68372 34.7812 13.25V24.8438H18.2188V13.25Z" fill="#7A28A0"/>
                            <path d="M50.8966 25.3671C50.2705 24.6997 49.2221 24.6632 48.5563 25.2893L31.9871 40.7934L24.3204 33.6186C23.6546 32.9942 22.6045 33.0306 21.9801 33.6964C21.3557 34.3639 21.3905 35.4123 22.0579 36.0383L30.8576 44.2716C31.1756 44.5697 31.583 44.7187 31.9888 44.7187C32.3946 44.7187 32.802 44.5697 33.12 44.2716L50.822 27.7091C51.4862 27.0847 51.521 26.0362 50.8966 25.3671Z" fill="#7A28A0"/>
                        </svg>
                        <h3 class="mt-2">Complete Privacy Assurance</h3>
                        <p>With Trinka Sensitive Data Plan, rest assured that we do not retain any of the sentences you send to our modules, the results you receive, or any actions you take. Your data remains off our servers, protecting your privacy and giving you peace of mind.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 56 56" fill="none">
                            <path d="M36.75 36.75H35V33.25C35 31.3935 34.2625 29.613 32.9497 28.3003C31.637 26.9875 29.8565 26.25 28 26.25C26.1435 26.25 24.363 26.9875 23.0503 28.3003C21.7375 29.613 21 31.3935 21 33.25V36.75H19.25C18.7859 36.75 18.3408 36.9344 18.0126 37.2626C17.6844 37.5908 17.5 38.0359 17.5 38.5V50.75C17.5 51.2141 17.6844 51.6593 18.0126 51.9874C18.3408 52.3156 18.7859 52.5 19.25 52.5H36.75C37.2141 52.5 37.6593 52.3156 37.9874 51.9874C38.3156 51.6593 38.5 51.2141 38.5 50.75V38.5C38.5 38.0359 38.3156 37.5908 37.9874 37.2626C37.6593 36.9344 37.2141 36.75 36.75 36.75ZM24.5 33.25C24.5 32.3217 24.8687 31.4315 25.5251 30.7751C26.1815 30.1187 27.0717 29.75 28 29.75C28.9283 29.75 29.8185 30.1187 30.4749 30.7751C31.1313 31.4315 31.5 32.3217 31.5 33.25V36.75H24.5V33.25ZM35 49H21V40.25H35V49Z" fill="#7A28A0"/>
                            <path d="M28 42C27.5359 42 27.0908 42.1844 26.7626 42.5126C26.4344 42.8408 26.25 43.2859 26.25 43.75V45.5C26.25 45.9641 26.4344 46.4092 26.7626 46.7374C27.0908 47.0656 27.5359 47.25 28 47.25C28.4641 47.25 28.9092 47.0656 29.2374 46.7374C29.5656 46.4092 29.75 45.9641 29.75 45.5V43.75C29.75 43.2859 29.5656 42.8408 29.2374 42.5126C28.9092 42.1844 28.4641 42 28 42Z" fill="#7A28A0"/>
                            <path d="M12.25 43.75C9.0011 43.75 5.88526 42.4594 3.58794 40.1621C1.29062 37.8647 0 34.7489 0 31.5C0 28.2511 1.29062 25.1353 3.58794 22.8379C5.88526 20.5406 9.0011 19.25 12.25 19.25C12.7141 19.25 13.1592 19.4344 13.4874 19.7626C13.8156 20.0908 14 20.5359 14 21C14 21.4641 13.8156 21.9092 13.4874 22.2374C13.1592 22.5656 12.7141 22.75 12.25 22.75C9.92936 22.75 7.70376 23.6719 6.06282 25.3128C4.42187 26.9538 3.5 29.1794 3.5 31.5C3.5 33.8206 4.42187 36.0462 6.06282 37.6872C7.70376 39.3281 9.92936 40.25 12.25 40.25C12.7141 40.25 13.1592 40.4344 13.4874 40.7626C13.8156 41.0907 14 41.5359 14 42C14 42.4641 13.8156 42.9093 13.4874 43.2374C13.1592 43.5656 12.7141 43.75 12.25 43.75Z" fill="#7A28A0"/>
                            <path d="M43.75 43.75C43.2859 43.75 42.8408 43.5656 42.5126 43.2374C42.1844 42.9093 42 42.4641 42 42C42 41.5359 42.1844 41.0907 42.5126 40.7626C42.8408 40.4344 43.2859 40.25 43.75 40.25C46.0706 40.25 48.2962 39.3281 49.9372 37.6872C51.5781 36.0462 52.5 33.8206 52.5 31.5C52.5 29.1794 51.5781 26.9538 49.9372 25.3128C48.2962 23.6719 46.0706 22.75 43.75 22.75C43.2859 22.75 42.8408 22.5656 42.5126 22.2374C42.1844 21.9092 42 21.4641 42 21C42 20.5359 42.1844 20.0908 42.5126 19.7626C42.8408 19.4344 43.2859 19.25 43.75 19.25C46.9989 19.25 50.1147 20.5406 52.4121 22.8379C54.7094 25.1353 56 28.2511 56 31.5C56 34.7489 54.7094 37.8647 52.4121 40.1621C50.1147 42.4594 46.9989 43.75 43.75 43.75Z" fill="#7A28A0"/>
                            <path d="M43.75 22.75C43.2859 22.75 42.8408 22.5656 42.5126 22.2374C42.1844 21.9092 42 21.4641 42 21C42 17.287 40.525 13.726 37.8995 11.1005C35.274 8.475 31.713 7 28 7C24.287 7 20.726 8.475 18.1005 11.1005C15.475 13.726 14 17.287 14 21C14 21.4641 13.8156 21.9092 13.4874 22.2374C13.1592 22.5656 12.7141 22.75 12.25 22.75C11.7859 22.75 11.3408 22.5656 11.0126 22.2374C10.6844 21.9092 10.5 21.4641 10.5 21C10.5 16.3587 12.3437 11.9075 15.6256 8.62563C18.9075 5.34374 23.3587 3.5 28 3.5C32.6413 3.5 37.0925 5.34374 40.3744 8.62563C43.6563 11.9075 45.5 16.3587 45.5 21C45.5 21.4641 45.3156 21.9092 44.9874 22.2374C44.6592 22.5656 44.2141 22.75 43.75 22.75Z" fill="#7A28A0"/>
                            <path d="M14 43.75H12.25C11.7859 43.75 11.3408 43.5656 11.0126 43.2374C10.6844 42.9092 10.5 42.4641 10.5 42C10.5 41.5359 10.6844 41.0908 11.0126 40.7626C11.3408 40.4344 11.7859 40.25 12.25 40.25H14C14.4641 40.25 14.9092 40.4344 15.2374 40.7626C15.5656 41.0908 15.75 41.5359 15.75 42C15.75 42.4641 15.5656 42.9092 15.2374 43.2374C14.9092 43.5656 14.4641 43.75 14 43.75Z" fill="#7A28A0"/>
                            <path d="M43.75 43.75H42C41.5359 43.75 41.0908 43.5656 40.7626 43.2374C40.4344 42.9092 40.25 42.4641 40.25 42C40.25 41.5359 40.4344 41.0908 40.7626 40.7626C41.0908 40.4344 41.5359 40.25 42 40.25H43.75C44.2141 40.25 44.6592 40.4344 44.9874 40.7626C45.3156 41.0908 45.5 41.5359 45.5 42C45.5 42.4641 45.3156 42.9092 44.9874 43.2374C44.6592 43.5656 44.2141 43.75 43.75 43.75Z" fill="#7A28A0"/>
                        </svg>
                        <h3 class="mt-2">Advanced<br class="d-lg-block d-none"/> Data <br class="d-lg-block d-none"/>Security</h3>
                        <p>We understand the critical importance of safeguarding your sensitive information, especially for enterprises aiming to shield their research from competitors. By not storing user data, we eliminate the risks of unauthorized access and putting the control solely in your hands.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <svg xmlns="http://www.w3.org/2000/svg" width="47" height="53" viewBox="0 0 47 53" fill="none">
                            <path d="M23.2889 2V29.5313" stroke="#7A28A0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M38.9009 5.04251H7.67703" stroke="#7A28A0" stroke-width="3" stroke-linejoin="round"/>
                            <path d="M16.2128 29.553H30.3662" stroke="#7A28A0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M13.3557 18.8222C13.3557 22.2003 10.8276 24.9427 7.70082 24.9565C4.57394 24.9565 2.0253 22.2501 2 18.8719L7.67789 5.04326L13.3557 18.8222Z" stroke="#7A28A0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M2.27693 18.8222H13.0788" stroke="#7A28A0" stroke-width="3"/>
                            <path d="M44.5795 18.8222C44.5795 22.2003 42.0515 24.9427 38.9247 24.9565C35.7978 24.9565 33.2491 22.2501 33.2238 18.8719L38.9017 5.04326L44.5795 18.8222Z" stroke="#7A28A0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M33.5008 18.8222H44.3026" stroke="#7A28A0" stroke-width="3"/>
                            <path d="M21.1802 38.7451L40.2999 38.7444" stroke="#7A28A0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M34.6233 51H11.955" stroke="#7A28A0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M6.27794 38.7444L16.1724 38.7451" stroke="#7A28A0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M36.0825 44.8727L40.2999 44.872" stroke="#7A28A0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M6.27794 44.872L18.3013 44.8727" stroke="#7A28A0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M23.3091 44.8726L31.0747 44.8724" stroke="#7A28A0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <h3 class="mt-2">Regulatory Simplicity<br class="d-lg-block d-none"/>&nbsp;</h3>
                        <p>Many regions have rigorous data protection
                            regulations, and for enterprises, safeguarding
                            valuable intellectual property is paramount.
                            Our approach of not storing user data
                            simplifies compliance efforts, minimizing legal
                            and regulatory risks while ensuring the
                            confidentiality of your research.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <section class="keySensitive section-lg">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10">
                    <div class="trinka-title mb-0 text-center text-white">
                        <h2 class="text-white">Key Features of Sensitive Data Plan</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p class="text-white">Enjoy a comprehensive set of features designed to address privacy concerns, enhance data security, and bolster trust. Here's what we offer:</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row match-box">
                <div class="col-lg-4 col-md-6 dflex">
                    <div class="features_cards c-card">
                        <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
                            <path d="M43.0625 39.75C42.1483 39.75 41.4062 40.4903 41.4062 41.4062V48.0312H11.5938V28.1562H16.5625H36.4375H41.4062C42.3205 28.1562 43.0625 27.4159 43.0625 26.5C43.0625 25.5841 42.3205 24.8438 41.4062 24.8438H38.0938V13.25C38.0938 6.85853 32.8931 1.65625 26.5 1.65625C20.1069 1.65625 14.9062 6.85853 14.9062 13.25V24.8438H9.9375C9.02325 24.8438 8.28125 25.5841 8.28125 26.5V49.6875C8.28125 50.6034 9.02325 51.3438 9.9375 51.3438H43.0625C43.9767 51.3438 44.7188 50.6034 44.7188 49.6875V41.4062C44.7188 40.4903 43.9767 39.75 43.0625 39.75ZM18.2188 13.25C18.2188 8.68372 21.9337 4.96875 26.5 4.96875C31.0663 4.96875 34.7812 8.68372 34.7812 13.25V24.8438H18.2188V13.25Z" fill="#7A28A0"/>
                            <path d="M50.8966 25.3671C50.2705 24.6997 49.2221 24.6632 48.5563 25.2893L31.9871 40.7934L24.3204 33.6186C23.6546 32.9942 22.6045 33.0306 21.9801 33.6964C21.3557 34.3639 21.3905 35.4123 22.0579 36.0383L30.8576 44.2716C31.1756 44.5697 31.583 44.7187 31.9888 44.7187C32.3946 44.7187 32.802 44.5697 33.12 44.2716L50.822 27.7091C51.4862 27.0847 51.521 26.0362 50.8966 25.3671Z" fill="#7A28A0"/>
                        </svg>
                        <h3>Complete Privacy Assurance</h3>
                        <p>With Trinka Sensitive Data Plan, rest assured that we do not retain any of the sentences you send to our modules, the results you receive, or any actions you take. Your data remains off our servers, protecting your privacy and giving you peace of mind.</p>
                    </div>
                </div>
    
                <div class="col-lg-4 col-md-6 dflex">
                    <div class="features_cards c-card">
                        <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 56 56" fill="none">
                            <path d="M36.75 36.75H35V33.25C35 31.3935 34.2625 29.613 32.9497 28.3003C31.637 26.9875 29.8565 26.25 28 26.25C26.1435 26.25 24.363 26.9875 23.0503 28.3003C21.7375 29.613 21 31.3935 21 33.25V36.75H19.25C18.7859 36.75 18.3408 36.9344 18.0126 37.2626C17.6844 37.5908 17.5 38.0359 17.5 38.5V50.75C17.5 51.2141 17.6844 51.6593 18.0126 51.9874C18.3408 52.3156 18.7859 52.5 19.25 52.5H36.75C37.2141 52.5 37.6593 52.3156 37.9874 51.9874C38.3156 51.6593 38.5 51.2141 38.5 50.75V38.5C38.5 38.0359 38.3156 37.5908 37.9874 37.2626C37.6593 36.9344 37.2141 36.75 36.75 36.75ZM24.5 33.25C24.5 32.3217 24.8687 31.4315 25.5251 30.7751C26.1815 30.1187 27.0717 29.75 28 29.75C28.9283 29.75 29.8185 30.1187 30.4749 30.7751C31.1313 31.4315 31.5 32.3217 31.5 33.25V36.75H24.5V33.25ZM35 49H21V40.25H35V49Z" fill="#7A28A0"/>
                            <path d="M28 42C27.5359 42 27.0908 42.1844 26.7626 42.5126C26.4344 42.8408 26.25 43.2859 26.25 43.75V45.5C26.25 45.9641 26.4344 46.4092 26.7626 46.7374C27.0908 47.0656 27.5359 47.25 28 47.25C28.4641 47.25 28.9092 47.0656 29.2374 46.7374C29.5656 46.4092 29.75 45.9641 29.75 45.5V43.75C29.75 43.2859 29.5656 42.8408 29.2374 42.5126C28.9092 42.1844 28.4641 42 28 42Z" fill="#7A28A0"/>
                            <path d="M12.25 43.75C9.0011 43.75 5.88526 42.4594 3.58794 40.1621C1.29062 37.8647 0 34.7489 0 31.5C0 28.2511 1.29062 25.1353 3.58794 22.8379C5.88526 20.5406 9.0011 19.25 12.25 19.25C12.7141 19.25 13.1592 19.4344 13.4874 19.7626C13.8156 20.0908 14 20.5359 14 21C14 21.4641 13.8156 21.9092 13.4874 22.2374C13.1592 22.5656 12.7141 22.75 12.25 22.75C9.92936 22.75 7.70376 23.6719 6.06282 25.3128C4.42187 26.9538 3.5 29.1794 3.5 31.5C3.5 33.8206 4.42187 36.0462 6.06282 37.6872C7.70376 39.3281 9.92936 40.25 12.25 40.25C12.7141 40.25 13.1592 40.4344 13.4874 40.7626C13.8156 41.0907 14 41.5359 14 42C14 42.4641 13.8156 42.9093 13.4874 43.2374C13.1592 43.5656 12.7141 43.75 12.25 43.75Z" fill="#7A28A0"/>
                            <path d="M43.75 43.75C43.2859 43.75 42.8408 43.5656 42.5126 43.2374C42.1844 42.9093 42 42.4641 42 42C42 41.5359 42.1844 41.0907 42.5126 40.7626C42.8408 40.4344 43.2859 40.25 43.75 40.25C46.0706 40.25 48.2962 39.3281 49.9372 37.6872C51.5781 36.0462 52.5 33.8206 52.5 31.5C52.5 29.1794 51.5781 26.9538 49.9372 25.3128C48.2962 23.6719 46.0706 22.75 43.75 22.75C43.2859 22.75 42.8408 22.5656 42.5126 22.2374C42.1844 21.9092 42 21.4641 42 21C42 20.5359 42.1844 20.0908 42.5126 19.7626C42.8408 19.4344 43.2859 19.25 43.75 19.25C46.9989 19.25 50.1147 20.5406 52.4121 22.8379C54.7094 25.1353 56 28.2511 56 31.5C56 34.7489 54.7094 37.8647 52.4121 40.1621C50.1147 42.4594 46.9989 43.75 43.75 43.75Z" fill="#7A28A0"/>
                            <path d="M43.75 22.75C43.2859 22.75 42.8408 22.5656 42.5126 22.2374C42.1844 21.9092 42 21.4641 42 21C42 17.287 40.525 13.726 37.8995 11.1005C35.274 8.475 31.713 7 28 7C24.287 7 20.726 8.475 18.1005 11.1005C15.475 13.726 14 17.287 14 21C14 21.4641 13.8156 21.9092 13.4874 22.2374C13.1592 22.5656 12.7141 22.75 12.25 22.75C11.7859 22.75 11.3408 22.5656 11.0126 22.2374C10.6844 21.9092 10.5 21.4641 10.5 21C10.5 16.3587 12.3437 11.9075 15.6256 8.62563C18.9075 5.34374 23.3587 3.5 28 3.5C32.6413 3.5 37.0925 5.34374 40.3744 8.62563C43.6563 11.9075 45.5 16.3587 45.5 21C45.5 21.4641 45.3156 21.9092 44.9874 22.2374C44.6592 22.5656 44.2141 22.75 43.75 22.75Z" fill="#7A28A0"/>
                            <path d="M14 43.75H12.25C11.7859 43.75 11.3408 43.5656 11.0126 43.2374C10.6844 42.9092 10.5 42.4641 10.5 42C10.5 41.5359 10.6844 41.0908 11.0126 40.7626C11.3408 40.4344 11.7859 40.25 12.25 40.25H14C14.4641 40.25 14.9092 40.4344 15.2374 40.7626C15.5656 41.0908 15.75 41.5359 15.75 42C15.75 42.4641 15.5656 42.9092 15.2374 43.2374C14.9092 43.5656 14.4641 43.75 14 43.75Z" fill="#7A28A0"/>
                            <path d="M43.75 43.75H42C41.5359 43.75 41.0908 43.5656 40.7626 43.2374C40.4344 42.9092 40.25 42.4641 40.25 42C40.25 41.5359 40.4344 41.0908 40.7626 40.7626C41.0908 40.4344 41.5359 40.25 42 40.25H43.75C44.2141 40.25 44.6592 40.4344 44.9874 40.7626C45.3156 41.0908 45.5 41.5359 45.5 42C45.5 42.4641 45.3156 42.9092 44.9874 43.2374C44.6592 43.5656 44.2141 43.75 43.75 43.75Z" fill="#7A28A0"/>
                        </svg>
                        <h3>Advanced Data Security</h3>
                        <p>We understand the critical importance of safeguarding your sensitive information, especially for enterprises aiming to shield their research from competitors. By not storing user data, we eliminate the risks of unauthorized access and putting the control solely in your hands.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 dflex">
                    <div class="features_cards c-card">
                        <svg xmlns="http://www.w3.org/2000/svg" width="47" height="53" viewBox="0 0 47 53" fill="none">
                            <path d="M23.2889 2V29.5313" stroke="#7A28A0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M38.9009 5.04251H7.67703" stroke="#7A28A0" stroke-width="3" stroke-linejoin="round"/>
                            <path d="M16.2128 29.553H30.3662" stroke="#7A28A0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M13.3557 18.8222C13.3557 22.2003 10.8276 24.9427 7.70082 24.9565C4.57394 24.9565 2.0253 22.2501 2 18.8719L7.67789 5.04326L13.3557 18.8222Z" stroke="#7A28A0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M2.27693 18.8222H13.0788" stroke="#7A28A0" stroke-width="3"/>
                            <path d="M44.5795 18.8222C44.5795 22.2003 42.0515 24.9427 38.9247 24.9565C35.7978 24.9565 33.2491 22.2501 33.2238 18.8719L38.9017 5.04326L44.5795 18.8222Z" stroke="#7A28A0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M33.5008 18.8222H44.3026" stroke="#7A28A0" stroke-width="3"/>
                            <path d="M21.1802 38.7451L40.2999 38.7444" stroke="#7A28A0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M34.6233 51H11.955" stroke="#7A28A0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M6.27794 38.7444L16.1724 38.7451" stroke="#7A28A0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M36.0825 44.8727L40.2999 44.872" stroke="#7A28A0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M6.27794 44.872L18.3013 44.8727" stroke="#7A28A0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M23.3091 44.8726L31.0747 44.8724" stroke="#7A28A0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <h3>Regulatory Simplicity</h3>
                        <p>Many regions have rigorous data protection
                            regulations, and for enterprises, safeguarding
                            valuable intellectual property is paramount.
                            Our approach of not storing user data
                            simplifies compliance efforts, minimizing legal
                            and regulatory risks while ensuring the
                            confidentiality of your research.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="use_trinka_other_app accomplishBrilliantWrap">
                        <div class="accomplishBrilliantSection">
                            <h2>Ready to Safeguard Your Sensitive Information?</h2>
                            <button type="button" (click)="scroll(target)" class="plansButtons">Let's Talk</button>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <div class="trinka-banner section-pd-sm">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10">
                    <div class="trinka-title text-center">
                        <h3 class="text-white">Ready to Safeguard Your Sensitive Information?</h3>
                    </div>
                    <div class="btn-group justify-content-center">
                        <button type="button" (click)="scroll(target)" class="white-btn">Let's Talk</button>
                    </div>
                </div>
            </div>  
        </div>
    </div>

    
    <div class="bg-gradient section-pd">
        <div class="intergrationOptions">
            <div class="trinka_container">
                <div class="row justify-content-center">
                    <div class="col-md-11 col-lg-10">
                        <div class="trinka-title mb-0 text-center">
                            <h2>Choose the Integration Option Best Suited for Your Need</h2>
                            <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="trinka_container">
                <div class="row align-items-center">
                    <div class="col-12">
                        <div class="media">
                            <div class="media-left">
                                <img src="../../../../assets/images/data-sensitive-js.png" loading="lazy" decoding="async" class="img-fluid" width="124" alt="trinka js" />
                            </div>
                            <div class="media-body">
                                <div class="media-title">
                                    <h3>01</h3>
                                    <p>Get Trinka </p>
                                    <span>JS</span>
                                </div>
                                <p>Enable Trinka for all your team members by simply inserting a small JavaScript (JS) code snippet into your browser or any application. TrinkaJS works in real-time and does not save anything on our servers.</p>
                            </div>
                        </div>
                        <div class="media">
                            <div class="media-left">
                                <img src="../../../../assets/images/data-api.png" loading="lazy" decoding="async" class="img-fluid" width="124" alt="trinka grammar checker api" />
                            </div>
                            <div class="media-body">
                                <div class="media-title">
                                    <h3>02</h3>
                                    <p>Get Trinka </p>
                                    <span>API</span>
                                </div>
                                <p>Trinka’s powerful API brings Trinka right into your existing applications, with a simple API architecture that is easy to integrate. Enable powerful real-time or passive grammar checks right within your application.</p>
                            </div>
                        </div>
                        <div class="media">
                            <div class="media-left">
                                <img src="../../../../assets/images/on-premise-data-sensitive.svg" loading="lazy" decoding="async" alt="trinka for premises" />
                            </div>
                            <div class="media-body">
                                <div class="media-title">
                                    <h3>03</h3>
                                    <p>Get Trinka </p>
                                    <span>On Premise</span>
                                </div>
                                <p>Ensure effective writing with Trinka, hosted on your enterprise infrastructure, be it local or cloud servers. Get a dedicated service personnel working with you for hassle-free integration.</p>
                            </div>
                        </div>
                        <div class="media">
                            <div class="media-left">
                                <img src="../../../../assets/images/cloud-data-sensitive.svg" loading="lazy" decoding="async" alt="trinka cloud editor" />
                            </div>
                            <div class="media-body">
                                <div class="media-title">
                                    <h3>04</h3>
                                    <p>Get Trinka </p>
                                    <span>Cloud</span>
                                </div>
                                <p>Collaborate securely with Trinka Cloud for Teams, accessing and editing your files effortlessly using your preferred browser. Elevate your writing, anytime, anywhere.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-pd " id="OrganizationType" #target>
            <div class="trinka_container">
                <div class="row justify-content-center">
                    <div class="col-md-11 col-lg-10">
                        <div class="trinka-title mb-0 text-center">
                            <h2>Contact us</h2>
                            <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                            <p>Please fill in the details and we'll respond as soon as possible.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-10 col-lg-8">
                        <div class="contact_us_form">
                            <form [formGroup]="contactForm" (ngSubmit)="sendContactUsForm()" class="DataSensitiveForm">
                                <div class="row">
                                    <div class="col-md-6 rightPaddingRemove">
                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('NatureBusinessType').valid && contactForm.get('NatureBusinessType').dirty) || (contactForm.get('NatureBusinessType').touched && contactForm.get('NatureBusinessType').pristine) ? 'TextFiledError' : '' ">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Nature of business*</mat-label>
                                                <input matInput placeholder="" formControlName="NatureBusinessType" class="form-control">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-md-6 leftPaddingRemove">
                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactFormSubjects').valid && contactForm.get('contactFormSubjects').dirty) || (contactForm.get('contactFormSubjects').touched && contactForm.get('contactFormSubjects').pristine) ? 'TextFiledError' : '' ">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Organization name*</mat-label>
                                                <input matInput placeholder="" formControlName="contactFormSubjects" class="form-control">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactFormWebsite').valid && contactForm.get('contactFormWebsite').dirty) || (contactForm.get('contactFormWebsite').touched && contactForm.get('contactFormWebsite').pristine) ? 'TextFiledError' : '' ">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Website*</mat-label>
                                                <input matInput type="URL" placeholder="" formControlName="contactFormWebsite" class="form-control">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="textbox_content form-group textareaBox" [ngClass]="(!contactForm.get('contactFormMessage').valid && contactForm.get('contactFormMessage').dirty) || (contactForm.get('contactFormMessage').touched && contactForm.get('contactFormMessage').pristine) ? 'TextFiledError' : '' ">
                                            <label for="">Tell us a bit about your requirement</label>
                                            <textarea formControlName="contactFormMessage" class="form-control" placeholder="E.g. approximare number of users, monthly word count volume per user, customizations needed, etc."></textarea>
                                        </div>
                                    </div>

                                    <div class="col-md-6 rightPaddingRemove">
                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactFormName').valid && contactForm.get('contactFormName').dirty) || (contactForm.get('contactFormName').touched && contactForm.get('contactFormName').pristine) ? 'TextFiledError' : '' ">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Name*</mat-label>
                                                <input matInput placeholder="" formControlName="contactFormName" class="form-control">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-md-6 leftPaddingRemove">
                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('DesignationTypes').valid && contactForm.get('DesignationTypes').dirty) || (contactForm.get('DesignationTypes').touched && contactForm.get('DesignationTypes').pristine) ? 'TextFiledError' : '' ">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Designation*</mat-label>
                                                <input matInput placeholder="" formControlName="DesignationTypes" class="form-control">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-md-6 rightPaddingRemove">
                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactFormEmail').valid && contactForm.get('contactFormEmail').dirty) || (contactForm.get('contactFormEmail').touched && contactForm.get('contactFormEmail').pristine) ? 'TextFiledError' : '' ">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Email*</mat-label>
                                                <input matInput placeholder="" formControlName="contactFormEmail" class="form-control">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-md-6 leftPaddingRemove">
                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactphoneNo').valid && contactForm.get('contactphoneNo').dirty) || (contactForm.get('contactphoneNo').touched && contactForm.get('contactphoneNo').pristine) ? 'TextFiledError' : '' ">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Contact Number* (incl. ISD code)</mat-label>
                                                <input type="number" matInput placeholder="" formControlName="contactphoneNo" class="form-control">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group text-center">
                                    <button type="submit" [disabled]="isSubmitting">Submit</button>
                                </div>

                            </form>
                        </div>
                        <div class="thank_section contact_us_form" *ngIf="thankSection">
                            <div class="form-group">
                                <h2>Thank You!</h2>
                                <p>We will contact you soon.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>