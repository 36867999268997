<section class="section-lg trustedby">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h3>Trusted by Global Leaders</h3>
            </div>
            <div class="col-12">
                <div id="client-logos_white" class="owl-carousel">
                    <div *ngFor="let logo of clientLogos" class="item">
                        <div class="clientLogo">
                          <img [src]="logo.src" [width]="logo.width" [height]="logo.height" loading="lazy" [class]="logo.class" [alt]="logo.alt" />
                        </div>
                    </div>
                    <!-- <div class="item">
                        <div class="clientLogo">
                            <img src="../../../assets/images/plagiarism-check/clients/elsevier.png" width="66" height="72" loading="lazy" class="filter" alt="elsevier">
                        </div>
                    </div>
                    <div class="item">
                        <div class="clientLogo">
                            <img src="../../../../assets/images/plagiarism-check/clients/mit.svg" width="62" height="33" loading="lazy" class="filter" alt="mit">
                        </div>
                    </div>
                    <div class="item">
                        <div class="clientLogo">
                            <img src="../../../../assets/images/plagiarism-check/clients/IEEE.svg" width="110" height="32" loading="lazy" class="filter" alt="IEEE">
                        </div>
                    </div>
                    <div class="item">
                        <div class="clientLogo">
                            <img src="../../../../assets/images/plagiarism-check/clients/stanford.svg" width="99" height="44" loading="lazy"  class="filter" alt="stanford">
                        </div>
                    </div>
                    <div class="item">
                        <div class="clientLogo">
                            <img src="../../../../assets/images/plagiarism-check/clients/wiley.svg" width="114" height="28" loading="lazy" class="filter" alt="wiley">
                        </div>
                    </div>
                    <div class="item">
                        <div class="clientLogo">
                            <img src="../../../../assets/images/plagiarism-check/clients/harvard.png" width="130" height="33" loading="lazy" alt="harvard">
                        </div>
                    </div>
                    <div class="item">
                        <div class="clientLogo">
                            <img src="../../../../assets/images/plagiarism-check/clients/caltech.svg" width="100" height="24" loading="lazy" class="filter" alt="caltech">
                        </div>
                    </div>
                    <div class="item">
                        <div class="clientLogo">
                            <img src="../../../../assets/images/plagiarism-check/clients/pfizer.svg" width="118" height="48" loading="lazy" class="filter" alt="pfizer">
                        </div>
                    </div>
                    <div class="item">
                        <div class="clientLogo">
                            <img src="../../../../assets/images/plagiarism-check/clients/abbott.svg" width="126" height="32" loading="lazy" class="filter" alt="abbott">
                        </div>
                    </div>
                    <div class="item">
                        <div class="clientLogo">
                            <img src="../../../../assets/images/plagiarism-check/clients/princeton-white.svg" width="132" height="36" loading="lazy" alt="princeton">
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>