import { Component, OnInit, PLATFORM_ID, Inject, Renderer2, ElementRef, HostListener } from '@angular/core';
import { WebsiteService } from 'src/app/_services/website.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { LinkService } from '../../_services/langhfre.service';
import * as $ from 'jquery';
import { Router } from '@angular/router';
declare function ire(string,{}): any;
@Component({
  selector: 'app-official-invoice',
  templateUrl: './official-invoice.component.html',
  styleUrls: ['./official-invoice.component.scss']
})
export class OfficialInvoiceComponent implements OnInit {

  contactForm: FormGroup;
  disabledSubmitButton: boolean = true;
  //billingInvoiceText: boolean = false;

  @HostListener('input') oninput() {

    if (this.contactForm.valid) {
      this.disabledSubmitButton = false;
    }
  }

  constructor(
    private fb: FormBuilder,
    private networkCall: WebsiteService,
    private toastr: ToastrService,
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private LinkService: LinkService,
    private elementRef: ElementRef,
    private router: Router
  ) {
    this.contactForm = fb.group({
      'contactFormName': ['', Validators.required],
      'contactFormEmail': ['', Validators.compose([Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)])],
      'contactFormContactNumber': ['', Validators.required],
      'contactFormOrgTitle': ['', Validators.required],
      'contactFormInquiryType': ['Billing/Invoice Support', Validators.required],
      'contactFormMessage': ['', Validators.required]
    });
   }

  ngOnInit(): void {
    ire('identify', {'customerId':'', customerEmail: '', customProfileId: this.generateGuid()})
    this.titleService.setTitle('Offering Country-specific invoices according to your location');
    this.metaTagService.updateTag({ name: 'description', content: "Your country-specific invoice helps in the reimbursement processes from your local company or institution" });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Offering Country-specific invoices according to your location' });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Your country-specific invoice helps in the reimbursement processes from your local company or institution' });
    this.metaTagService.updateTag({ name: 'keywords', content: 'local invoices, invoice customization, invoice and payment options, invoice for organization, global invoicing' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/local-invoice' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/local-invoice' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/local-invoice' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/local-invoice' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/local-invoice' });
    //this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/local-invoice' });
    //this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/local-invoice' });
    //this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/local-invoice' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/local-invoice' });
    //this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/local-invoice' });
    

    $('#currencyselector').change(function(){
      $('.currency-container').hide();
      $('#' + $(this).val()).show();
    });
  }

  processForm() {
    // console.log("gggg")
    // if(this.contactForm.value.contactFormEmail && this.contactForm.value.contactFormName && this.contactForm.value.contactFormSubjects && this.contactForm.value.contactFormMessage){
    if (this.contactForm.valid) {
      console.log("vlaidated")
      const selectedInquiryType = this.contactForm.value.contactFormInquiryType;
      // Create the subject with the selected inquiry type
      const subject = `Inquiry Type: ${selectedInquiryType}`;
      var query = this.contactForm.value.contactFormName + "||" + this.contactForm.value.contactFormMessage + "||" + this.contactForm.value.contactFormContactNumber + "||" + this.contactForm.value.contactFormOrgTitle + "||" + this.contactForm.value.contactFormInquiryType
      this.networkCall.postContactUs(this.contactForm.value.contactFormEmail, query, this.router.url).subscribe(result => {
        this.toastr.success("Thanks! We will contact you shortly.")
        this.contactForm.reset();
        //this.billingInvoiceText = false;
      }, error => {
        // this.shareService.errorHandller(error);
      })
    } else {
      // this.toastr.warning("Please fill in the necessary details on the form.")
      this.validateAllFormFields(this.contactForm);
    }
    // const allInfo = `My name is ${this.name}. My email is ${this.email}. My message is ${this.message}`;
    // alert(allInfo);
  }


  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }
  // displayInvoiceEmail(event){
  //   if(event.target.value == "Billing/Invoice Support"){
  //     this.billingInvoiceText = true;
  //   }else{
  //     this.billingInvoiceText = false;
  //   }
    
  // }

  ngAfterViewChecked(): void {
    this.setSliderPosition();
  }

  onTabClick(event: any): void {
    this.setSliderPosition();
  }

  generateGuid() {
    var result, i, j;
    result = '';
    for(j=0; j<32; j++) {
      if( j == 8 || j == 12 || j == 16 || j == 20)
        result = result + '-';
      i = Math.floor(Math.random()*16).toString(16).toUpperCase();
      result = result + i;
    }
    return result;
  }

  private setSliderPosition(): void {
    const tabs = this.elementRef.nativeElement.querySelectorAll('#tile-1 .nav-pills a');
    const activeTab = this.elementRef.nativeElement.querySelector('#tile-1 .nav-pills .active');

    if (activeTab) {
      const tabsContainer = this.elementRef.nativeElement.querySelector('#tile-1 .nav-pills');
      const position = activeTab.offsetLeft - tabsContainer.offsetLeft;
      const width = activeTab.offsetWidth;

      const slider = this.elementRef.nativeElement.querySelector('#tile-1 .slider');
      slider.style.left = position + 'px';
      slider.style.width = width + 'px';
    }
  }

}
