import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import * as $ from 'jquery';


@Component({
  selector: 'app-global-leaders',
  templateUrl: './global-leaders.component.html',
  styleUrls: ['./global-leaders.component.scss']
})
export class GlobalLeadersComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  clientLogos = [
    { src: '../../../assets/images/plagiarism-check/clients/elsevier.png', width: 66, height: 72, alt: 'elsevier', class: 'filter' },
    { src: '../../../../assets/images/plagiarism-check/clients/mit.svg', width: 62, height: 33, alt: 'mit', class: 'filter' },
    { src: '../../../../assets/images/plagiarism-check/clients/IEEE.svg', width: 110, height: 32, alt: 'IEEE', class: 'filter' },
    { src: '../../../../assets/images/plagiarism-check/clients/stanford.svg', width: 99, height: 44, alt: 'stanford', class: 'filter' },
    { src: '../../../../assets/images/plagiarism-check/clients/wiley.svg', width: 114, height: 28, alt: 'wiley', class: 'filter' },
    { src: '../../../../assets/images/plagiarism-check/clients/harvard.png', width: 130, height: 33, alt: 'harvard', class: '' },
    { src: '../../../../assets/images/plagiarism-check/clients/caltech.svg', width: 100, height: 24, alt: 'caltech', class: 'filter' },
    { src: '../../../../assets/images/plagiarism-check/clients/pfizer.svg', width: 118, height: 48, alt: 'pfizer', class: 'filter' },
    { src: '../../../../assets/images/plagiarism-check/clients/abbott.svg', width: 126, height: 32, alt: 'abbott', class: 'filter' },
    { src: '../../../../assets/images/plagiarism-check/clients/princeton-white.svg', width: 132, height: 36, alt: 'princeton', class: '' }
  ];

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      $.getScript('../../../assets/js/owl.carousel.min.js', function () { });
    }
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      $.getScript('../../../assets/js/home_client_slider.js', function () { });
    }
  }

}
