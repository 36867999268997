import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery';
import { LinkService } from '../../../_services/langhfre.service';
declare function ire(string,{}): any;
@Component({
  selector: 'app-journal-finder',
  templateUrl: './journal-finder.component.html',
  styleUrls: ['./journal-finder.component.scss']
})
export class JournalFinderComponent implements OnInit, AfterViewInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private LinkService: LinkService
  ) { }

  ngOnInit() {
    ire('identify', {'customerId':'', customerEmail: '', customProfileId: this.generateGuid()})
    this.titleService.setTitle('Trinka Journal Finder | Suggesting Journals for your Paper');
    this.metaTagService.updateTag({ name: 'description', content: "Trinka's Journal Finder identifies the right journal by comparing the concepts in your paper against millions of past publications and publication trends. Journal Finder saves your time and ensures maximum citations by helping you select the right journal for your paper." });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Trinka Journal Finder | Suggesting Journals for your Paper' });
    this.metaTagService.updateTag({ property: 'og:description', content: "Trinka's Journal Finder identifies the right journal by comparing the concepts in your paper against millions of past publications and publication trends. Journal Finder saves your time and ensures maximum citations by helping you select the right journal for your paper." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/features/journal-finder' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/features/s1.svg' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Trinka Journal Finder | Suggesting Journals for your Paper' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Trinka's Journal Finder identifies the right journal by comparing the concepts in your paper against millions of past publications and publication trends. Journal Finder saves your time and ensures maximum citations by helping you select the right journal for your paper." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://trinka.ai/assets/images/features/s1.svg' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/features/journal-finder' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/features/journal-finder' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/features/journal-finder' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/features/journal-finder' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/features/journal-finder' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/features/journal-finder' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/features/journal-finder' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/features/journal-finder' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/features/journal-finder' });
    if (isPlatformBrowser(this.platformId)) {
      // $.getScript('../../../assets/js/particles.min.js', function(){});
      //$.getScript('../../../assets/js/wisepop.js', function () { });
    }
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {"@context":"https://schema.org","@graph":[{"@type":"WebPage","@id":"https://www.trinka.ai/features/journal-finder/#webpage","url":"https://www.trinka.ai/features/journal-finder/","name":"Trinka Journal Finder | Suggesting Journals for your Paper","isPartOf":{"@id":"https://trinka.ai/#website"},"datePublished":"2022-07-09T05:22:52+00:00","dateModified":"2023-02-10T15:18:24+00:00","description":"Trinka's Journal Finder identifies the right journal by comparing the concepts in your paper against millions of past publications and publication trends. Journal Finder saves your time and ensures maximum citations by helping you select the right journal for your paper.","inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://www.trinka.ai/features/journal-finder/"]}]},{"@type":"WebSite","@id":"https://trinka.ai/#website","url":"https://trinka.ai/","name":"Trinka AI","description":"Advanced Grammar Checker for Academic and Professional Writing - Trinka","publisher":{"@id":"https://trinka.ai/#organization"},"alternateName":"The Complete Writing Assistant","inLanguage":"en-US"},{"@type":"Organization","@id":"https://trinka.ai/#organization","name":"Trinka AI","url":"https://trinka.ai/","logo":{"@type":"ImageObject","inLanguage":"en-US","@id":"https://trinka.ai/assets/images/trinka-logo.png","url":"https://trinka.ai/assets/images/trinka-logo.png","contentUrl":"","caption":"Trinka AI"},"image":{"@type":"ImageObject","@id":"https://trinka.ai/assets/images/"},"sameAs":["https://www.facebook.com/thetrinkaai/","https://twitter.com/thetrinkaai","https://www.linkedin.com/company/thetrinkaai/","https://www.youtube.com/@Trinka-ai"]}]}
    `;

    this._renderer2.appendChild(this._document.head, script);

    let script1 = this._renderer2.createElement('script');
    script1.type = `application/ld+json`;
    script1.text = `
    {"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Trinka Ai","item":"https://www.trinka.ai/"},{"@type":"ListItem","position":2,"name":"Trinka Features","item":"https://www.trinka.ai/features"},{"@type":"ListItem","position":3,"name":"Journal Finder","item":"https://www.trinka.ai/features/journal-finder"}]}
    `;
    this._renderer2.appendChild(this._document.head, script1);

    let script2 = this._renderer2.createElement('script');
    script2.type = `application/ld+json`;
    script2.text = `
    {"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"How is Trinka’s Journal Finder different from others?","acceptedAnswer":{"@type":"Answer","text":"Trinka's Journal Finder uses advanced AI concept-matching technologies to help you locate journals across different disciplines and publications to find the most appropriate journal for your manuscript."}},{"@type":"Question","name":"Is Journal Finder limited to open source journals?","acceptedAnswer":{"@type":"Answer","text":"No. Trinka's database is not limited to open source journals. The database contains papers drawn from sources such as Crossref and PubMed. In case you would like to look for open source journals only, you can filter the results."}},{"@type":"Question","name":"Can I enter my choice of publisher or journal, other than the suggestions Trinka displays?","acceptedAnswer":{"@type":"Answer","text":"Yes, you can do that. Journal Finder will check your paper to determine whether it is suitable for publishing in your journal of choice and will also suggest suitable alternatives."}},{"@type":"Question","name":"Based on my abstract, how many suggestions am I likely to get in a single report?","acceptedAnswer":{"@type":"Answer","text":"Currently, the report is Predatory publishing is a fraudulent practice that researchers need to be aware of. Predatory journals “are fake scholarly publications lack the usual features of editorial oversight and transparent policies and operating procedures that are expected from legitimate peer-reviewed publications.” (COPE Council. COPE Discussion Document: Predatory Publishing. November 2019. https://doi.org/10.24318/cope.2019.3.6).not shareable. We will make that happen in the future!"}}]}
    `;
    this._renderer2.appendChild(this._document.head, script2);

    let script3 = this._renderer2.createElement('script');
    script3.type = `application/ld+json`;
    script3.text = `
    {"@context":"http://schema.org","@type":"VideoObject","name":"Journal Finder | Find the Right Journal to Publish Your Paper - Trinka Product Walkthrough","description":"Journal Finder identifies the right journal by comparing the concepts in your paper with millions of past publications and publication trends. Even better, you can see similar published papers too to make the right decision faster!  Journal Finder saves your time and ensures maximum citations by helping you choose  the right journal to submit your paper to.  We hope this feature will boost your publication success.  #journalfinder #journalfindingtool #publishpaper #published","thumbnailUrl":"https://i.ytimg.com/vi/fuWNwHYFPFM/default.jpg","uploadDate":"2022-11-02T05:48:58Z","duration":"PT1M53S","embedUrl":"https://www.youtube.com/embed/fuWNwHYFPFM","interactionCount":"437"}
    `;
    this._renderer2.appendChild(this._document.head, script3);
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      $('#closedPopup, #wordPluginPopup').click(function () {
        $('iframe.youtubeVideoForTryDemo').remove()
      });

      $('#VewiVideoSection').click(function () {
        if (!$('#iframe').length) {
          $('#iframeHolder').html('<iframe class="youtubeVideoForTryDemo" id="iframe" src="https://www.youtube.com/embed/fuWNwHYFPFM" width="100%" height="450"></iframe>');
        }
      });

    }
  }

  generateGuid() {
    var result, i, j;
    result = '';
    for(j=0; j<32; j++) {
      if( j == 8 || j == 12 || j == 16 || j == 20)
        result = result + '-';
      i = Math.floor(Math.random()*16).toString(16).toUpperCase();
      result = result + i;
    }
    return result;
  }

}
