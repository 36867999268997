import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  testimonials = [
    {
      "author": "James Smith",
      "designation": "Academic Editor, USA",
      "message": "First, I love Trinka. Congratulations on developing something that extensively aids editing, especially critical grammar conventions and word count reductions.",
      "photo": "clientPhotos/James-Smith.jpg"
    },
    {
      "author": "Dauda Ayuba",
      "designation": "Research Assistant, Ahmadu Bello University, Zaria, Nigeria",
      "message": "I have been using the Trinka service, particularly to proofread manuscripts since 2023. This will service check and correct grammar, spelling writing advice, and enhancements of your manuscript. The platform’s intuitive design and comprehensive feedback have greatly enhanced my writing skills. The ability to provide context-specific suggestions makes it a standout tool to ensure clarity and precision in my work. I highly recommend Trinka to anyone looking to improve their writing quality.",
      "photo": "clientPhotos/d-ayuba.jpg"
    },
    {
      "author": "Dr. Abdalla Machano",
      "designation": "General Practioner at Jitimai Referral Hospital, Tanzania<br/>Founder & Director, Kadi Hai",
      "message": "Trinka AI has significantly improved my academic writing. It has helped me become more confident in my writing by quickly and accurately identifying grammatical and spelling errors.",
      "photo": "clientPhotos/abdalla-machano.jpg"
    },
    {
      "author": "Rosa Diaz Sandoval",
      "designation": "Physics and Mathematics PhD, Mexico",
      "message": "Writing texts with correct English grammar and style is not an easy task for people who speak other languages. Sometimes you cannot find little mistakes in your document without help and moreover for academic writing. I tried Trinka AI as a tool of automatic correction and was quite glad with the suggested corrections and the feedback. With the feedback you understand why the suggested change must be taken into account.",
      "photo": "clientPhotos/Rosa-Diaz-Sandoval.jpg"
    },
    {
      "author": "Fouad Khalifa",
      "designation": "MSc Student ",
      "message": "Trinka has a robust proofreading feature that allows me to effortlessly upload entire manuscript documents and receive fully tracked changes in a single step. Moreover, its unique supporting tools such as citation checker and journal finder, significantly enhance the efficiency of my research endeavours.",
      "photo": "clientPhotos/client-photo.jpg"
    },
    {
      "author": "Jesse Rumball-Smith",
      "designation": "Student, New Zealand",
      "message": "I just wanted to say thank you for such a great and free service, all the other grammar checkers and writing assistants I have tried don’t have nearly the depth of features let alone all available on a free subscription. As a student with little income available I am extremely grateful that unlike all others companies you don’t limit features on the free plan. This is a fantastic product and given the income I would definitely upgrade to the premium. I've let all my fellow students know about Trinka as it is seriously a life saver.",
      "photo": "clientPhotos/Noor-Al-rishi.png"
    },
    {
      "author": "Dr. Farooq Rathore",
      "designation": "Assistant Professor and Head of Department, Pakistan",
      "message": "I have mainly used it to improve the language, grammar, and syntax of the scientific manuscripts that I write for national and international biomedical journals. I have found the interface to be user friendly and the procedure itself is efficient and hassle-free. The auto-edit is a great help and the supplementary page with the revision summary is an excellent idea.",
      "photo": "clientPhotos/Dr_Farooq_Rathore.jpg"
    },
    {
      "author": "Dr. Nitin Wahi",
      "designation": "Project Scientist - Pathfinder Research and Training Foundation",
      "message": "Trinka AI is a great tool to help beginners in research publication. Its grammar editing, online proofreading with file download, citation checker, plagiarism checker, paraphraser, and journal finder provide amazing features at your fingertips. Even the free Trinka Basic plan can be invaluable for researchers in their early stages or at PG levels, helping them improve project reports and papers to meet publication standards. Overall, I'm extremely impressed with Trinka AI, as it stands out among research writing tools. Give it a try and you will love it! Thanks Trinka AI team for this valuable tool.",
      "photo": "clientPhotos/client-photo.jpg"
    },
    {
      "author": "Georgieva Milena",
      "designation": "Researcher",
      "message": "Trinka.ai is my most favorite choice for grammar and spelling edit. The application works correctly, is fast and tidily! Completely satisfied!",
      "photo": "clientPhotos/Georgieva-Milena.jpg"
    },
    {
      "author": "Dinesh Gupta",
      "designation": "Radiologist, India",
      "message": "The grammar checking tool exceeds Grammarly! I re-checked some documents that I had checked earlier with Grammarly. I was pleasantly surprised to see such a product from India!",
      "photo": "clientPhotos/Dinesh-Gupta.jpg"
    },
    {
      "author": "Aftab Hussain",
      "designation": "Clinical Psychologist, Pakistan",
      "message": "Trinka has awesome features. Advanced level of academic writing checker and suggestion are so original. I would highly recommend to others.",
      "photo": "clientPhotos/aftab-hussain.jpg"
    },
    {
      "author": "Edith Davies",
      "designation": "PhD Scholar, UK",
      "message": "I have tested Trinka and am impressed with its fine capabilities. It did better than the grammar checkers I use. Well done! I have told my colleagues in my research lab to use Trinka. Hope it is widely used in academia.",
      "photo": "clientPhotos/Edith-Davies.jpg"
    },
    {
      "author": "Noor Al-rishi",
      "designation": "Chemistry Scholar, Saudi Arabia",
      "message": "Trinka AI's suggested corrections and feedback astounded me. It is simple to use and extremely beneficial",
      "photo": "clientPhotos/Noor-Al-rishi.png"
    },
    {
      "author": "Nwaji Jude",
      "designation": "Researcher",
      "message": "It's been a wonderful experience using Trinka and I almost can't submit any manuscript without re-evaluating with it. In summary, Trinka has been a life-saver.",
      "photo": "clientPhotos/Nwaji-Jude.jpg"
    },
    {
      "author": "Stumpf Curtis",
      "designation": "Researcher",
      "message": "I trust and use Trinka as a final spot check for my edited documents. It helps me find the few remaining errors that I have missed during the primary editing. I really like using this platform for my writing. One particular feature that works well for me is the thumbs down button.",
      "photo": "clientPhotos/Stumpf-Curtis.jpg"
    },
    {
      "author": "Dyke Gareth",
      "designation": "Researcher",
      "message": "I think Trinka.ai is a very interesting and potentially useful idea, especially for editors/publishers/journals. I found it very useful when I checked my editing work on the platform.",
      "photo": "clientPhotos/Dyke-Gareth.jpg"
    },
    {
      "author": "Bhargava Sharma",
      "designation": "Epidemiologist, India",
      "message": "I am happy with the overall performance. I have been using Grammarly for a long while, but when it comes to academic or technical writing, Trinka is definitely a much better option. Academic document settings and auto file edit are my favorite features.",
      "photo": "clientPhotos/Bhargava-Sharma.jpg"
    },
    {
      "author": "Ralph Bailey",
      "designation": "Neurologist, USA",
      "message": "Trinka has become my go-to tool for grammar corrections. The AI-powered corrections and language enhancements made writing a lot more easier. Now I am able to focus more on research than on writing an error-free document.",
      "photo": "clientPhotos/Ralph-Bailey.jpg"
    },
    {
      "author": "Thomas Andres",
      "designation": "Cardiologist, Brazil",
      "message": "The grammar correction and suggestion based on the AMA style guide is really impressive! Trinka is indeed the best grammar correction tool for medical writing.",
      "photo": "clientPhotos/Thomas-Andres.jpg"
    }
  ]  

}
