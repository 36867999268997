<div id="particles-js"></div>
<div class="about_us_wrap">
    <div class="our_mission_sections">
        <div class="our_mission_overlay"></div>
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="mission_content">
                        <h1>Trinka is extensively used by users across top universities, institutes, publishers and media houses.</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="client_logo_section">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="trinka-title">
                        <h2>Universities and Institutes</h2>
                        <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                    </div>
                    <div class="cover_wrapper">
                        <div id="client-logos" class="owl-carousel">
                            <div class="item">
                                <div class="client_inners">
                                  <img src="../../../assets/images/MIT.jpg" alt="universities">
                                  <img src="../../../assets/images/StanfordUniversity.jpg" alt="universities">
                                  <img src="../../../assets/images/HarvardMedicalSchool.jpg" alt="universities">
                                  <img src="../../../assets/images/Caltech.jpg" alt="universities">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client_inners">
                                    <img src="../../../assets/images/UCL.jpg" alt="universities">
                                    <img src="../../../assets/images/ImperialCollegeLondon.jpg" alt="universities">
                                    <img src="../../../assets/images/TheUniversityOfShicago.jpg" alt="universities">
                                    <img src="../../../assets/images/NTU_Singapore.jpg" alt="universities">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client_inners">
                                    <img src="../../../assets/images/DukeNUS.jpg" alt="universities">
                                    <img src="../../../assets/images/PrincetonUniversity.jpg" alt="universities">
                                    <img src="../../../assets/images/CornellUniversity.jpg" alt="universities">
                                    <img src="../../../assets/images/Yale.jpg" alt="universities">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client_inners">
                                    <img src="../../../assets/images/client_logos/nippon-institute.png" alt="universities">
                                    <img src="../../../assets/images/Duke.jpg" alt="universities">
                                    <img src="../../../assets/images/UniversityOfManchester.jpg" alt="universities">
                                    <img src="../../../assets/images/IIT_Kanpur.jpg" alt="universities">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client_inners">
                                    <img src="../../../assets/images/SNU_Logo.jpg" alt="universities">
                                    <img src="../../../assets/images/NYULogo.jpg" alt="universities">
                                    <img src="../../../assets/images/TheUniversityOfSydney.jpg" alt="universities">
                                    <img src="../../../assets/images/TheUniversityOfSheffield.jpg" alt="universities">
                                </div>
                            </div>
    
                            <div class="item">
                                <div class="client_inners">
                                    <img src="../../../assets/images/UNSW.jpg" alt="universities">
                                    <img src="../../../assets/images/POSTECH_University.jpg" alt="universities">
                                    <img src="../../../assets/images/YonseiUniversity.jpg" alt="universities">
                                    <img src="../../../assets/images/TheRockefellerUniversity.jpg" alt="universities">
                                </div>
                            </div>
    
                            <div class="item">
                                <div class="client_inners">
                                    <img src="../../../assets/images/UNESP.jpg" alt="universities">
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>