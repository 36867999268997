import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LinkService } from '../../_services/langhfre.service';
declare function ire(string,{}): any;
@Component({
  selector: 'app-campus-ambassador',
  templateUrl: './campus-ambassador.component.html',
  styleUrls: ['./campus-ambassador.component.scss']
})
export class CampusAmbassadorComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private LinkService: LinkService
  ) { }

  ngOnInit(): void {
    ire('identify', {'customerId':'', customerEmail: '', customProfileId: this.generateGuid()})
    this.titleService.setTitle('AI Campus Ambassador Program');
    this.metaTagService.updateTag({ name: 'description', content: "This program aims to build a strong global community of students passionate about AI." });
    this.metaTagService.updateTag({ property: 'og:title', content: 'AI Campus Ambassador Program' });
    this.metaTagService.updateTag({ property: 'og:description', content: '​This program aims to build a strong global community of students passionate about AI.​' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/campus-ambassador' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'AI Campus Ambassador Program' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "This program aims to build a strong global community of students passionate about AI." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/campus-ambassador' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/campus-ambassador' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/campus-ambassador' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/campus-ambassador' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/campus-ambassador' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/campus-ambassador' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/campus-ambassador' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/campus-ambassador' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/campus-ambassador' });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  generateGuid() {
    var result, i, j;
    result = '';
    for(j=0; j<32; j++) {
      if( j == 8 || j == 12 || j == 16 || j == 20)
        result = result + '-';
      i = Math.floor(Math.random()*16).toString(16).toUpperCase();
      result = result + i;
    }
    return result;
  }

}
