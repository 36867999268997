<div class="trinka_cloud_wrap">
    <div id="particles-js"></div>
    <!-- <div class="cloud_top_banners">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="trinka_cloud_banner_text">
                        <h1>Consistency Check</h1>
                        <h2>Give Your Writing<br /> 
                            A Professional Touch</h2>
                        <p>Save time and give your writing a professional finish in just a few clicks. Trinka's Consistency Check fixes inconsistencies that are hard-to-find, especially in a lengthy document.</p>
                        <a href="https://cloud.trinka.ai/signup?_rg=GB" id="autoEditGetStart" target="_blank">Get Started</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="trinka_cloud_banner_img">
                        <img src="../../../assets/images/Consistency-Checks.png" alt="consistency-check">
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <section class="mainTopBanner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="topBannerWrapper">
                        <div class="top-banner-left">
                            <h1>Consistency Check</h1>
                            <h2>Give Your Writing<br /> A Professional Touch</h2>
                            <p>Trinka's Consistency Check fixes inconsistencies that are hard-to-find, especially in a lengthy document. Save time and give your writing a professional finish.</p>
                            <div class="btn-group">
                                <a href="https://cloud.trinka.ai/signup?_rg=GB" class="filled-btn" target="_blank">Get Started</a>
                            </div>
                        </div>
                        <div class="top-banner-right">
                            <img src="../../../assets/images/Consistency-Checks.png" alt="consistency-check"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="howItWorks section-pd list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="list-wrapper-image">
                        <a href="" data-toggle="modal" data-target="#wordPluginPopup" id="VewiVideoSection">
                            <img src=".././../../assets/images/ConsistencyCheckThumbnail.png" loading="lazy" decoding="async" alt="consistency-check" class="img-fluid eposter-img">
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="list-wrapper-content-2">
                        <div class="trinka-title mb-0">
                            <h2>See How It Works</h2>
                            <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                            <p>Check out the easy walk-through video to get started with Consistency Check in no time.</p>
                            <p>It is a detailed step-by-step tutorial to help you use Trinka to its fullest.</p>
                            <p class="mb-0">Have more questions? Browse through our FAQs <a style="color: #0000ff;" (click)="scroll(target)" class="link text-link">here</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="cloud_key_features section-pd list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-12 col-lg-12">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Key Features of Trinka Consistency Check</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>Trinka identifies several inconsistencies to make your writing look professional.<br /> The following types of inconsistencies are identified and corrected:</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-none">
                        <div class="feature_num">01</div>
                        <h3>Spellings</h3>
                        <p>Trinka identifies different spellings <span class="yellow-bg">(American vs. British)</span> used for the same word.</p>
                        <div class="custom_radioButtons">
                            <p>
                                <input type="radio" id="colourSection" name="spellingchecks" checked>
                                <label for="colourSection">colour (4 occurences) </label>
                            </p>
                            <p>
                                <input type="radio" id="colorSections" name="spellingchecks">
                                <label for="colorSections">color (4 occurences) </label>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <div class="feature_num">02</div>
                        <h3>Hyphens and dashes</h3>
                        <p>Trinka finds inconsistent use of <span class="yellow-bg">hyphens and dashes</span> and makes them consistent with a click. </p>
                        <div class="custom_radioButtons">
                            <p>
                                <input type="radio" id="ultra-violet" name="dashesCehcks" checked>
                                <label for="ultra-violet">ultra-violet (5 occurrences)
                                </label>
                            </p>
                            <p>
                                <input type="radio" id="ultra_violet" name="dashesCehcks">
                                <label for="ultra_violet">ultra violet (5 occurrences)
                                </label>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <div class="feature_num">03</div>
                        <h3>Number style </h3>
                        <p>Interchangeably using <span class="yellow-bg">numerals and spelled out numbers</span> is one of the most common inconsistencies. Trinka helps you avoid it with a click.</p>
                        <div class="custom_radioButtons">
                            <div class="custom_radioButtons">
                                <p>
                                    <input type="radio" id="averagesixyears" name="sadfdashesCehcks" checked>
                                    <label for="averagesixyears">
                                        <b>average six years</b>
                                            <img src="../../../assets/images/spacingArrow.png" alt="arrow-icon" />
                                        <b>average 6 years</b>
                                    </label>
                                </p>
                                <p>
                                    <input type="radio" id="averagesixyearsss" name="sadfdashesCehcks">
                                    <label for="averagesixyearsss">
                                        <b>average 6 years</b>
                                            <img src="../../../assets/images/spacingArrow.png" alt="arrow-icon"/>
                                        <b>average six years</b>
                                    </label>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <div class="feature_num">04</div>
                        <h3>Symbols</h3>
                        <p class="fearureCardText">Trinka understands when you interchangeably <span class="yellow-bg">use symbols or spell them out</span>. </p>
                        <div class="custom_radioButtons">
                            <p>
                                <input type="radio" id="exampeFourContent_one" name="exampeFourContent" checked>
                                <label for="exampeFourContent_one">
                                    <b>TNF-alpha</b>
                                    <img src="../../../assets/images/spacingArrow.png" alt="arrow-icon" /><b>TNF-α</b>
                                </label>
                            </p>
                            <p>
                                <input type="radio" id="exampeFourContent_two" name="exampeFourContent">
                                <label for="exampeFourContent_two">
                                    <b>TNF-α</b>
                                    <img src="../../../assets/images/spacingArrow.png" alt="arrow-icon" />
                                    <b>TNF-alpha</b>
                                </label>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <div class="feature_num">05</div>
                        <h3>Spacing</h3>
                        <p class="fearureCardText">Spaces are difficult to spot and therefore easily missed. Trinka helps you eliminate <span class="yellow-bg">inconsistent spacing</span> throughout your writing in a couple of clicks. </p>
                        <div class="custom_radioButtons">
                            <p>
                                <input type="radio" id="exampeFiveContent_one" name="exampeFiveContent" checked>
                                <label for="exampeFiveContent_one">
                                    <b>p<0</b> 
                                    <img src="../../../assets/images/spacingArrow.png" alt="arrow-icon" /> 
                                    <b>p < 0</b> 
                                </label>
                            </p>
                            <p>
                                <input type="radio" id="exampeFiveContent_two" name="exampeFiveContent">
                                <label for="exampeFiveContent_two">
                                    <b>p < 0</b>
                                    <img src="../../../assets/images/spacingArrow.png" alt="arrow-icon" />
                                    <b>p<0</b>
                                </label>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <div class="feature_num">06</div>
                        <h3 class="d-block">Titles (Tables, Figures, Boxes) <div class="beta-badges">NEW</div> </h3>
                        <p class="fearureCardText">Trinka corrects inconsistencies <span class="yellow-bg">in titles of across tables, figures, and boxes,</span> with a single click. </p>
                        <div class="custom_radioButtons">
                            <p>
                                <input type="radio" id="exampeSixContent_one" name="exampeSixContent" checked>
                                <label for="exampeSixContent_one">
                                    <b>Figure 1.</b> 
                                    <img src="../../../assets/images/spacingArrow.png" alt="arrow-icon" /> 
                                    <b>Fig 1.</b> 
                                </label>
                            </p>
                            <p>
                                <input type="radio" id="exampeSixContent_two" name="exampeSixContent">
                                <label for="exampeSixContent_two">
                                    <b>Fig 1.</b>
                                    <img src="../../../assets/images/spacingArrow.png" alt="arrow-icon" />
                                    <b>Figure 1.</b>
                                </label>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <div class="feature_num">07</div>
                        <h3>P-Values <div class="beta-badges">NEW</div></h3>
                        <p class="fearureCardText">Trinka ensures <span class="yellow-bg">uniformity in casing, spacing, and the presence of zero</span> in P-value expressions for a consistent statistical presentation. </p>
                        <div class="custom_radioButtons">
                            <p>
                                <input type="radio" id="exampeSevenContent_one" name="exampeSevenContent" checked>
                                <label for="exampeSevenContent_one">
                                    <b>p < 0.01</b> 
                                    <img src="../../../assets/images/spacingArrow.png" alt="arrow-icon" /> 
                                    <b>p < .01</b> 
                                </label>
                            </p>
                            <p>
                                <input type="radio" id="exampeSevenContent_two" name="exampeSevenContent">
                                <label for="exampeSevenContent_two">
                                    <b>p < .01</b>
                                    <img src="../../../assets/images/spacingArrow.png" alt="arrow-icon" />
                                    <b>p < 0.01</b>
                                </label>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <div class="feature_num">08</div>
                        <h3 class="d-block">Characters with Accent <div class="beta-badges">NEW</div></h3>
                        <p class="fearureCardText">Trinka checks if the accents or <span class="yellow-bg">diacritical marks in words</span> are consistent in the manuscript.</p>
                        <div class="custom_radioButtons">
                            <p>
                                <input type="radio" id="exampeEightContent_one" name="exampeEightContent" checked>
                                <label for="exampeEightContent_one">
                                    <b>café</b> 
                                    <img src="../../../assets/images/spacingArrow.png"alt="arrow-icon" /> 
                                    <b>cafe</b> 
                                </label>
                            </p>
                            <p>
                                <input type="radio" id="exampeEightContent_two" name="exampeEightContent">
                                <label for="exampeEightContent_two">
                                    <b>cafe</b>
                                    <img src="../../../assets/images/spacingArrow.png" alt="arrow-icon" />
                                    <b>café</b>
                                </label>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="trinka-banner section-pd-sm">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8">
                    <div class="trinka-title text-center">
                        <h3 class="text-white">Ensure Your Writing is Consistent and Professional</h3>
                    </div>
                    <div class="btn-group justify-content-center">
                        <a href="https://cloud.trinka.ai/signup?_rg=GB" class="white-btn" target="_blank">Get Started</a>
                    </div>
                </div>
            </div>  
        </div>
    </div>

    <app-safe-secure></app-safe-secure>


    <div class="faqQuestionAnwser section-pd pt-0" #target>
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10">
                    <div class="trinka-title text-center">
                        <h2>Frequently Asked Questions</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="faqQuestionsList">
                <div class="accordion" id="faq">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="faqContainer">
                                <div class="card">
                                    <div class="card-header" id="QuestionOne">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionOneContent" aria-expanded="true" aria-controls="QuestionOneContent">
                                            How does Trinka's Consistency Check work?</a>
                                    </div>
    
                                    <div id="QuestionOneContent" class="collapse" aria-labelledby="QuestionOne" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Trinka’s Consistency Check automatically finds variations in several common inconsistencies
                                                made by writers and lists them all for you. It also gives you correction options.
                                                All you need to do is choose the right style and confirm. Trinka does the rest.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="QuestionTwo">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionTwoContent" aria-expanded="true"
                                            aria-controls="QuestionTwoContent">Where can I find the Consistency Check feature?</a>
                                    </div>
    
                                    <div id="QuestionTwoContent" class="collapse" aria-labelledby="QuestionTwo" data-parent="#faq">
                                        <div class="card-body">
                                            <p>The Consistency Check feature is currently available on the Cloud editor to all users.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="faqContainer">
                                <div class="card">
                                    <div class="card-header" id="QuestionThree">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionThreeContent" aria-expanded="true"
                                            aria-controls="QuestionThreeContent">
                                            Does the Consistency Check feature require credits?
                                        </a>
                                    </div>
    
                                    <div id="QuestionThreeContent" class="collapse" aria-labelledby="QuestionThree" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Currently, no credits are required to use the Consistency Check feature. You can
                                                use the feature as many times as you like.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="QuestionFour">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionFourContent" aria-expanded="true"
                                            aria-controls="QuestionFourContent">
                                            When should I use the Consistency Check feature?
                                        </a>
                                    </div>
    
                                    <div id="QuestionFourContent" class="collapse" aria-labelledby="QuestionFour" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Ideally, you should run the Consistency Check feature after you have finished writing.
                                                However, you can run it as many times and whenever you see the need for it.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
    <div class="otherApps section-pd-sm">
        <div class="trinka_container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="trinka-title text-center">
                        <h3 class="text-white">Use Trinka in Other Apps</h3>
                    </div>
                    <ul>
                        <li>
                            <a [routerLink]="['/trinkacloud']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Other_apps_TrinkaCloud.svg" alt="trinka cloud">
                                <h3>Cloud</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/wordplugin']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Others_App_MSWord.svg" alt="ms word">
                                <h3>MS Word Add-in</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/browser-plugin']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/other_Apps_browser.svg" alt="browser-plugin">
                                <h3>Browser Plug-in</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/enterprise']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Other_Apps_enterprise.svg" alt="enterprise">
                                <h3>Enterprise <small>(API, JS, & On-Premise)</small></h3>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="wordPluginPopup" tabindex="-1" role="dialog" aria-labelledby="wordPluginLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body" style="padding: 0;">
                <button type="button" id="closedPopup" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div id="iframeHolder"></div>

                <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/hTaMLLHICCo"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                <!-- <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/z-TVcRwVp3A"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe> -->
            </div>
        </div>
    </div>
</div>