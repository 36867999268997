import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { LinkService } from '../../_services/langhfre.service';
import * as $ from 'jquery';
declare function ire(string,{}): any;

@Component({
  selector: 'app-work-with-us',
  templateUrl: './work-with-us.component.html',
  styleUrls: ['./work-with-us.component.scss']
})
export class WorkWithUsComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private LinkService: LinkService
  ) { }

  ngOnInit() {
    ire('identify', {'customerId':'', customerEmail: '', customProfileId: this.generateGuid()})
    this.titleService.setTitle('Work with Us | Trinka');
    this.metaTagService.updateTag({ name: 'description', content: 'Trinka is an AI-powered writing assistant that empowers every person and organization to write better. We believe in going beyond all boundaries. Up for a challenge? Join us now!' });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Work with Us | Trinka' });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Trinka is an AI-powered writing assistant that empowers every person and organization to write better. We believe in going beyond all boundaries. Up for a challenge? Join us now!' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/workwithus' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/workwithus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/workwithus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/workwithus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/workwithus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/workwithus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/workwithus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/workwithus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/workwithus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/workwithus' });
    if (isPlatformBrowser(this.platformId)) {
      //$.getScript('../../../assets/js/wisepop.js', function () { });
    }
  }

  generateGuid() {
    var result, i, j;
    result = '';
    for(j=0; j<32; j++) {
      if( j == 8 || j == 12 || j == 16 || j == 20)
        result = result + '-';
      i = Math.floor(Math.random()*16).toString(16).toUpperCase();
      result = result + i;
    }
    return result;
  }

}
