import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject } from '@angular/core';
import * as $ from 'jquery';
import { Title, Meta } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
declare function ire(string,{}): any;
@Component({
  selector: 'app-professional-editing-service',
  templateUrl: './professional-editing-service.component.html',
  styleUrls: ['./professional-editing-service.component.scss']
})
export class ProfessionalEditingServiceComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit(): void {
    ire('identify', {'customerId':'', customerEmail: '', customProfileId: this.generateGuid()})
    this.titleService.setTitle('Professional Editing Service Details | Trinka AI');
    this.metaTagService.updateTag({ name: 'description', content: "Academic English editing services by expert PhD/Master's native-English editors for your publication success." });
    this.metaTagService.updateTag({ property: 'og:title', content: "Professional Editing Service Details | Trinka AI" });
    this.metaTagService.updateTag({ property: 'og:description', content: "Academic English editing services by expert PhD/Master's native-English editors for your publication success."});
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/professional-editing-service' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ name: 'robots', content: 'noindex, nofollow' });
    if (isPlatformBrowser(this.platformId)){
      // $.getScript('../../../assets/js/particles.min.js', function(){});
    }
  }

  generateGuid() {
    var result, i, j;
    result = '';
    for(j=0; j<32; j++) {
      if( j == 8 || j == 12 || j == 16 || j == 20)
        result = result + '-';
      i = Math.floor(Math.random()*16).toString(16).toUpperCase();
      result = result + i;
    }
    return result;
  }

}
