import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery';
import { LinkService } from '../../_services/langhfre.service';

declare function ire(string,{}): any;

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit, AfterViewInit {

  constructor(private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private LinkService: LinkService
  ) { }

  ngOnInit() {
    ire('identify', {'customerId':'', customerEmail: '', customProfileId: this.generateGuid()})
    this.titleService.setTitle('Academic and Technical Writing Assistant and Grammar Checker - Trinka');
    this.metaTagService.updateTag({ name: 'description', content: "Enhance and instantly correct your writing with the best English grammar checker. Trinka's advance features highlight mistakes and provide contextual suggestions that makes your writing shine." });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Academic and Technical Writing Assistant and Grammar Checker - Trinka' });
    this.metaTagService.updateTag({ property: 'og:description', content: "Enhance and instantly correct your writing with the best English grammar checker. Trinka's advance features highlight mistakes and provide contextual suggestions that makes your writing shine." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/features' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Academic and Technical Writing Assistant and Grammar Checker - Trinka' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Enhance and instantly correct your writing with the best English grammar checker. Trinka's advance features highlight mistakes and provide contextual suggestions that makes your writing shine." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/features' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/features' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/features' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/features' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/features' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/features' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/features' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/features' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/features' });
    if (isPlatformBrowser(this.platformId)) {
      // $.getScript('../../../assets/js/particles.min.js', function(){});
      $.getScript('../../../assets/js/typod.js', function () { });
      //$.getScript('../../../assets/js/wisepop.js', function () { });
      //document.getElementById('main_header').classList.add('stripeHeader');
    }
  }
  ngAfterViewInit() {

  }
  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      //document.getElementById('main_header').classList.remove('stripeHeader');
    }
  }

  generateGuid() {
    var result, i, j;
    result = '';
    for(j=0; j<32; j++) {
      if( j == 8 || j == 12 || j == 16 || j == 20)
        result = result + '-';
      i = Math.floor(Math.random()*16).toString(16).toUpperCase();
      result = result + i;
    }
    return result;
  }

}
