<section class="banner-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="banner-title">
                    <div class="title"><h1>Free Grammar Checker</h1></div>
                    <p>Enhance your writing instantly with Trinka's AI-powered free grammar checker. Trinka goes beyond simple grammar and spelling corrections, taking your writing to the next level.<br/> Available in English and Spanish.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="multiTabSection">
                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link active" data-toggle="tab" href="#EnglishGrammarChecker" role="tab" aria-label="english">English</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" data-toggle="tab" href="#SpanishGrammarChecker" role="tab" aria-label="spanish">Spanish</a>
                        </li>
                        <!-- <li class="nav-item" role="presentation">
                            <a class="nav-link" data-toggle="tab" href="#GermanGrammarChecker" role="tab" aria-label="german">German</a>
                        </li> -->
                    </ul><!-- Tab panes -->
                    <div class="tab-content">
                        <div class="tab-pane active" id="EnglishGrammarChecker" role="tabpanel">
                            <app-english-grammar-checker></app-english-grammar-checker>
                        </div>
                        <div class="tab-pane" id="SpanishGrammarChecker" role="tabpanel">
                            <app-spanish-grammar-checker></app-spanish-grammar-checker>
                        </div>
                        <!-- <div class="tab-pane" id="GermanGrammarChecker" role="tabpanel">
                            <app-german-grammar-checker></app-german-grammar-checker>
                        </div> -->
                    </div>
                </div>

                <!-- <div class="languageTabSection">
                    <ul>
                        <li>
                            <a class="languageSelected" href="https://www.trinka.ai/grammar-checker">English</a>
                        </li>
                        <li>
                            <a href="https://www.trinka.ai/es/corrector-gramatical">Spanish</a>
                        </li>
                        <li>
                            <a href="https://www.trinka.ai/de/grammatik-pruefen">German</a>
                        </li>
                    </ul>
                </div>
                <lib-multilingual-editor-marketing
                [endpoint]="editorConfig.endpoint"
                [lang]="editorConfig.lang"
                [minHeight]="editorConfig.minHeight"
                [sessionLimit]="editorConfig.sessionLimit"
                (showSnackBarEvent)="showSnackBar($event)"
                (sessionExhaustedEvent)="sessionExhausted($event)"
                (openFeedbackModalEvent)="openFeedbackModal($event)"
                ></lib-multilingual-editor-marketing> -->

            </div>
        </div>
    </div>
    <span class="shape-1"></span>
    <span class="shape-2"></span>
    <span class="shape-3"></span>
</section>

<div class="trinka-banner section-pd-sm" id="real-time">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-10">
                <div class="trinka-title text-center">
                    <h2 class="text-white">Get real-time grammar check on your favorite websites for FREE!</h2>
                </div>
                <div class="btn-group justify-content-center">
                    <a  id="GCChromeButton" href="https://chrome.google.com/webstore/detail/trinka-ai-for-chrome/bbcmnbnmngpeofmpcdlcfalbniefegbp" target="_blank" class="white-btn"><img src="../../../assets/images/NewChromeIcon.svg" loading="lazy" decoding="async" width="32" height="32" alt="chrome"> Download for Chrome</a>
                    <a  id="GCFirFoxButton" href="https://addons.mozilla.org/en-US/firefox/addon/trinka-ai-firefox-browser/" target="_blank" class="white-btn"><img src="../../../assets/images/NewFirefoxIcon.svg" loading="lazy" decoding="async"  width="32" height="32" alt="firefox "> Download for Firefox</a>
                    <a  id="GCEdgeButton" href="https://microsoftedge.microsoft.com/addons/detail/trinka-ai-for-edge/kjhkcgjfbpppkbhhaocagkmfkkiibeap" target="_blank" class="white-btn"><img src="../../../assets/images/EdgeWhite_BGAndOutline.svg" loading="lazy" decoding="async"  width="32" height="32" alt="edge"> Download for Edge</a>
                </div>
            </div>
        </div>  
    </div>
</div>

<div class="signupmobile trinka-banner section-pd-sm">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="trinka-title mb-0 text-center">
                    <h3 class="text-white mb-4">Your free Trinka account</h3>
                    <p class="text-white">Check your writing for up to 5000 words every month and notice an all-round improvement in your language and style.</p>
                    <a href="https://cloud.trinka.ai/signup?_rg=GB" target="_blank">Sign up NOW!</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="section-pd">
    <div class="trinka_container">
        <div class="row">
            <div class="col-sm-12">
                <div class="trinka-title mb-0 text-center">
                    <h2>Best Free Online Grammar Checker</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p class="w-100">Trinka is an AI-powered, free grammar checker and language enhancement writing assistant designed specifically for academic and research paper proofreading. In addition to advanced grammar checks, Trinka improves your writing for vocabulary, tone, syntax, and more. Trinka offers advanced grammar checks and contextual spelling corrections instantly and lets you improve your writing in minutes. </p>
                    <p class="w-100">Trinka provides real-time writing suggestions and advanced grammar checks to help professionals and academicians ensure formal, concise, and engaging writing. Trinka's AI grammar checker is trained on millions of well-written papers and articles covering 1300+ subject areas including medicine, life sciences, physical sciences, engineering, humanities, business, and arts to give you the most relevant suggestions for your subject. 
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="trinka-banner section-pd-sm">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-lg-11 col-lg-10">
                <div class="trinka-title mb-0 text-center">
                    <h3 class="text-white mb-4">Who can use this Grammar Checker?</h3>
                    <p class="text-white">Trinka is ideal for anyone looking to improve their English writing skills. Trinka's free grammar checker tool is trusted by academics, copywriters, marketers, product teams, bloggers, and more.</p>
                </div>
            </div>
        </div>  
    </div>
</div>

<div class="the_best_grammer_sections section-pd">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-11 col-lg-10">
                <div class="trinka-title mb-0 text-center">
                    <h2>More than a Grammar and <br class="d-lg-block d-none"/>Punctuation Checker</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="best_grammer_cards">
                    <h3>Advanced Grammar Checks</h3>
                    <div class="content_body_best_grammer">
                        <div class="best_content_text">
                            <p>Trinka goes beyond the basic grammar and spelling checks to evaluate your writing for advanced grammar errors and suggests language enhancements to improve your writing quality.</p>
                        </div>
                        <div class="mini_card_grammer">
                            <!-- <h4>Technical word choice</h4> -->
                            <p>Neither the healthy individuals nor the patient <span
                                class="wrong_text">recognize</span> <span
                                class="right_text"> recognizes</span> the visual discrepancies.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="best_grammer_cards">
                    <h3>Sentence Structure</h3>
                    <div class="content_body_best_grammer">
                        <div class="best_content_text">
                            <p>Trinka's sentence corrector helps you structure your sentences better based on your subject matter, making your writing easier to read for your audience.<br/>&nbsp;</p>
                        </div>
                        <div class="mini_card_grammer">
                            <!-- <h4>Syntax</h4> -->
                            <p>The students don't enjoy <span class="wrong_text">to go</span> <span
                                class="right_text"> going</span> over the same rules again and again.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="best_grammer_cards">
                    <h3>Word Choice</h3>
                    <div class="content_body_best_grammer">
                        <div class="best_content_text">
                            <p>Trinka fixes the errors in word choice that make your writing unclear. Trinka makes your writing concise and clear, so that you can communicate your intent accurately.</p>
                        </div>
                        <div class="mini_card_grammer">
                            <!-- <h4>Advance Spelling</h4> -->
                            <p>One of the reasons is <span class="wrong_text">because</span> <span
                                class="right_text"> that</span> the fabrication cost <span
                                class="wrong_text">for</span> <span class="right_text"> of</span> steel
                            members depends upon the material.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="best_grammer_cards">
                    <h3>Advanced Spell Check</h3>
                    <div class="content_body_best_grammer">
                        <div class="best_content_text">
                            <p>Trinka’s free spell checker understands the context of your writing and helps you pick the right word for your topic. No more awkward spelling errors! </p>
                        </div>
                        <div class="mini_card_grammer">
                            <!-- <h4>Sensitive language</h4> -->
                            <p>The <span class="wrong_text">Bonferoni</span> <span
                                class="right_text"> Bonferroni</span> test was used to check for
                            statistical significance.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="section-pd trinka-platform">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
                <div class="trinka-title text-center ">
                    <h2>Other AI Tools Available with Trinka Grammar Checker
                    </h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-6">
                <div class="platform-card">
                    <div class="card-top" id="paraphraser">
                        <span class="capella"><img src="../../../assets/images/homepage/capella-shape.svg" width="619" height="583"  loading="lazy" decoding="async" alt="capella" /></span>
                        <h3>
                            <img src="../../../assets/images/paraphraser-icon.svg" width="24" height="24" loading="lazy" decoding="async" alt="paraphraser-icon">
                            Paraphraser
                        </h3>
                        <div class="list-wrapper-image">
                            <picture>
                                <source srcset="../../../assets/images/homepage/pharaphraser.webp" type="image/webp">
                                <img src="../../../assets/images/homepage/pharaphraser.png" width="322" height="263" loading="lazy" decoding="async" alt="paraphraser">
                            </picture>
                            <!-- <img src="../../../assets/images/homepage/pharaphraser.svg" loading="lazy" decoding="async" class="img-fluid" alt="paraphraser icon" style="transform: translate(30px, 0px);" /> -->
                        </div>
                    </div>
                    <div class="card-bottom">
                        <p>Experience enhanced clarity and tone in your writing by rephrasing text with Trinka's Paraphrasing Tool. Designed to deliver coherent rephrasing while maintaining the original meaning.
                        </p>
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="platform-card" id="plagiarism-check">
                    <div class="card-top">
                        <span class="capella"><img src="../../../assets/images/homepage/capella-shape1.svg" width="619" height="583" loading="lazy" decoding="async" alt="capella" /></span>
                        <h3>
                            <img src="../../../assets/images/plag-check-icon.svg" width="24" height="24" loading="lazy" decoding="async" alt="Plagiarism Checker">
                            Plagiarism Checker
                        </h3>
                        <div class="list-wrapper-image">
                            <picture>
                                <source srcset="../../../assets/images/homepage/plag-check.webp" type="image/webp">
                                <img src="../../../assets/images/homepage/plag-check.png" class="plag-check-img" width="332" height="275" loading="lazy" decoding="async" alt="Plagiarism Checker">
                            </picture>
                            <!-- <img src="../../../assets/images/homepage/plag-check.svg" loading="lazy" decoding="async" class="img-fluid" alt="Plagiarism Checker" style="transform: translate(30px, 0px);" /> -->
                        </div>
                    </div>
                    <div class="card-bottom">
                        <p>Gain access to gold-standard plagiarism checkers powered by <img src="../../../assets/images/ithenticate_logo.svg" width="100" height="13" loading="lazy" decoding="async" alt="iThenticate"> and <img src="../../../assets/images/turnitin_logo.svg" width="67" height="21" loading="lazy" alt="turnitin"> at an affordable price. Scan your document against the largest database, which includes paid content from all top publishers worldwide.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="btn-group text-center mt-40 justify-content-center">
                    <a href="https://cloud.trinka.ai/signup?_rg=GB" class="filled-btn" target="_blank">Get Started</a>
                </div>
            </div>
        </div>   
    </div>       
</section>

<div class="cloud_key_features section-pd list-wrapper-style-2">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-12">
                <div class="trinka-title mb-0 text-center">
                    <h2>Who Can Benefit From This Grammar Checker?</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p>Trinka is perfect for anyone wanting to enhance their English writing skills. Our free grammar checker is trusted by academics, copywriters, marketers, product teams, bloggers, and more.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-none">
                    <h3>Researchers</h3>
                    <p>
                        For research scholars and academics, Trinka is an essential tool for improving articles, dissertations, and research papers. It helps maintain academic standards and ensures clear communication of ideas.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards">
                    <h3>Professionals</h3>
                    <p>
                        Trinka enhances your professional documents, such as reports, presentations, and emails. It corrects spelling mistakes, improves sentence structure, and enhances overall professionalism.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1">
                    <h3>Students</h3>
                    <p>
                        Trinka is an essential tool for students at all levels. It helps edit essays, research papers, and assignments, ensuring perfect grammar, clarity, and coherence.                        
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1">
                    <h3>Writers of Various Content</h3>
                    <p>
                        Trinka is versatile and can assist with any type of writing.  It can revise personal statements, cover letters, novels, social media posts, and more, ensuring your message is communicated clearly.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="use_trinka_other_app section-pd-sm">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="trinka-title mb-0 text-center">
                    <h3 class="text-white">Get Trinka Where You Need It</h3>
                </div>
                <div class="use_trinka_content">
                    <div class="others_app_name">
                        <ul>
                            <li>
                                <a [routerLink]="['/trinkacloud']">
                                    <div class="app_name_content" >
                                        <div class="app_name_content_overlay"></div>
                                        <img src="../../../assets/images/Other_apps_TrinkaCloud.svg" width="45" height="45" loading="lazy" decoding="async"  alt="Free grammar checker tool">
                                        <h3>Cloud</h3>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a  [routerLink]="['/wordplugin']">
                                    <div class="app_name_content">
                                        <div class="app_name_content_overlay"></div>
                                        <img src="../../../assets/images/wordAdd-in1.svg" width="45" height="45" loading="lazy" decoding="async"  alt="word - Trinka AI grammar checker ">
                                        <h3>MS Word</h3>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a  [routerLink]="['/browser-plugin']">
                                    <div class="app_name_content">
                                        <div class="app_name_content_overlay"></div>
                                        <img src="../../../assets/images/NewChromeIcon.svg" width="45" height="45" loading="lazy" decoding="async"  alt="chrome - Trinka AI grammar checker">
                                        <h3>Chrome</h3>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a  [routerLink]="['/browser-plugin']">
                                    <div class="app_name_content">
                                        <div class="app_name_content_overlay"></div>
                                        <img src="../../../assets/images/NewFirefoxIcon.svg" width="45" height="45" loading="lazy" decoding="async"  alt="firefox - Trinka AI grammar checker">
                                        <h3>Firefox</h3>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a  [routerLink]="['/browser-plugin']">
                                    <div class="app_name_content">
                                        <div class="app_name_content_overlay"></div>
                                        <img src="../../../assets/images/EdgeWhite_BGAndOutline.svg" width="45" height="45" loading="lazy" decoding="async"  alt="edge - Trinka AI grammar checker">
                                        <h3>Edge</h3>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a  [routerLink]="['/browser-plugin']">
                                    <div class="app_name_content">
                                        <span class="safariComingSoonTag">Coming Soon</span>
                                        <div class="app_name_content_overlay"></div>
                                        <img src="../../../assets/images/safari_logo.png" width="45" height="45" loading="lazy" decoding="async"  alt="safari - Trinka AI grammar checker">
                                        <h3>Safari</h3>
                                    </div>
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="section-pd list-wrapper-style-2">
    <div class="trinka_container">
        <div class="row align-items-center flex-row-reverse">
            <div class="col-md-6">
                <div class="list-wrapper-content-2">
                    <h3>Make Your Writing More Effective with Trinka’s Grammar Checker</h3>
                    <p>Trinka's AI grammar checker checks your writing and improves your sentences holistically – for tone, structure, word choice, and readability – unlike other sentence checkers. Streamline your workflow with our professional grammar checker- it incorporates three products in one. Get a sentence checker, spell checker, and a punctuation checker. </p>
                    <p>Get more than just grammar check and ensure your writing is clear and concise.</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="list-wrapper-image">
                    <picture>
                        <source srcset="../../../assets/images/WritingMoreEffective.webp" type="image/webp">
                        <img src="../../../assets/images/WritingMoreEffective.png" class="grammar-check-img" loading="lazy" decoding="async" alt="grammar checker">
                    </picture>
                    <!-- <img src="../../../assets/images/WritingMoreEffective.png" loading="lazy" decoding="async"  alt="grammar checker"> -->
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-pd list-wrapper-style-2 pt-0">
    <div class="trinka_container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="list-wrapper-content-2 pr-80">
                    <h3>Learn Grammar with Trinka's Grammar Checker
                    </h3>
                    <p>Every grammar correction is a learning opportunity. Trinka's grammar checker doesn't just highlight errors but also explains how to fix them, which will help you become a better writer. You get an indicative score of the overall language quality of the content too.</p>
                    <p>Through Trinka's AI grammar checker, students can learn how to write effectively and inculcate best writing practices.</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="list-wrapper-image features-img">
                    <picture>
                        <source srcset="../../../assets/images/LearnGrammarwithTrinka.webp" type="image/webp">
                        <img src="../../../assets/images/LearnGrammarwithTrinka.png" class="grammar-check-img" loading="lazy" decoding="async" alt="grammar checker">
                    </picture>
                    <!-- <img src="../../../assets/images/LearnGrammarwithTrinka.png" loading="lazy" decoding="async"  alt="grammar checker"> -->
                </div>
            </div>
        </div>
    </div>
</section>

<div class="testimonial-section text-center section-pd">
    <app-testimonial></app-testimonial>
</div>

<div class="trinka-banner section-pd-sm">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8">
                <div class="trinka-title mb-0 text-center">
                    <h3 class="text-white mb-4">What are you waiting for?</h3>
                    <div class="btn-group justify-content-center">
                        <a href="https://cloud.trinka.ai/signup?_rg=GB" target="_blank" class="white-btn" id="grammerSignUpButton">
                            <!-- <i class="fa fa-sign-in"></i> -->
                            <span>Register for Free</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>  
    </div>
</div>    

<!-- <div class="ready_to_levelUp">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="level_up_heading">
                    <h3>What are you waiting for?</h3>
                    <a href="https://cloud.trinka.ai/signup?_rg=GB" target="_blank" id="grammerSignUpButton">
                        <span>Register for Free</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="faqQuestionAnwser section-pd" #target>
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
                <div class="trinka-title text-center">
                    <h2>Trinka’s Grammar Checker FAQ</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="faqQuestionsList">
            <div class="accordion" id="faq">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="faqContainer">
                            <div class="card">
                                <div class="card-header" id="QuestionGCOne">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCOneContent" aria-expanded="true"
                                        aria-controls="QuestionGCOneContent">
                                        <h4>How does Trinka compare to other free online grammar checkers?</h4>
                                    </a>
                                </div>
    
                                <div id="QuestionGCOneContent" class="collapse"
                                    aria-labelledby="QuestionGCOne" data-parent="#faq">
                                    <div class="card-body">
                                        <p>We tested <b>5,778 words from five academic domains on other free grammar checkers.</b> We checked for the accuracy of corrections, style guide preferences, conciseness, and contextual spelling checks – and stood out proficiently. For a detailed comparison, check out our <a  [routerLink]="['/trinka-vs-grammarly-vs-language-tool']">comparison whitepaper</a>.</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="card">
                                <div class="card-header" id="QuestionIcons">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionIconsContent" aria-expanded="true"
                                        aria-controls="QuestionIconsContent">
                                        <h4>Why Trinka Grammar Checker?</h4>
                                    </a>
                                </div>
    
                                <div id="QuestionIconsContent" class="collapse" aria-labelledby="QuestionIcons" data-parent="#faq">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <table class="" cellpadding="5">
                                                    <tr>
                                                        <td>⚡ AI-Powered Grammar Checker</td>
                                                         <td>🌐 Support: US, UK, JP, TW, CN, KR, PT, ES </td>
                                                    </tr>
                                                    <tr>
                                                        <td>🆓 Signup: Free</td>
                                                        <td>🛢️ Large Academic Database</td>
                                                    </tr>
                                                    <tr>
                                                        <td>✅ Checks: Powerful Grammar, Plagiarism and Spelling</td>
                                                        <td>📋 Easy to Search and Use </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            
                            <div class="card">
                                <div class="card-header" id="QuestionGCTwo">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCTwoContent" aria-expanded="true"
                                        aria-controls="QuestionGCTwoContent"><h4>How are Trinka's paid plans different from other free grammar checkers?</h4></a>
                                </div>
    
                                <div id="QuestionGCTwoContent" class="collapse"
                                    aria-labelledby="QuestionGCTwo" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka isn't your typical grammar checker. It's an excellent choice for academic and technical writing. <b>Trinka's free plan includes more features than most other premium grammar checkers.</b> <a  [routerLink]="['/pricing']"> Our premium plan</a> is reasonably priced and offers more features that cover your every writing need!</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCThree">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCThreeContent" aria-expanded="true"
                                        aria-controls="QuestionGCThreeContent"><h4>What platforms/apps can Trinka's grammar checker be used on?</h4></a>
                                </div>
    
                                <div id="QuestionGCThreeContent" class="collapse"
                                    aria-labelledby="QuestionGCThree" data-parent="#faq">
                                    <div class="card-body">
                                        <p><b>Trinka’s grammar checker can be used on any web browser.</b> It is also available as a <a  [routerLink]="['/browser-plugin']">browser plug-in</a> for the most popular browsers such as Chrome, Firefox, Safari, and Edge, to help you get grammar corrections on your favourite websites. Trinka is also available on MS Word add-in for premium users.</p>
                                    </div>
                                </div>
                            </div>
    
                            <div class="card">
                                <div class="card-header" id="QuestionGCFour">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCFourContent" aria-expanded="true"
                                        aria-controls="QuestionGCFourContent"><h4>Can Trinka’s grammar checker be integrated with my proprietary software?</h4></a>
                                </div>
    
                                <div id="QuestionGCFourContent" class="collapse"
                                    aria-labelledby="QuestionGCFour" data-parent="#faq">
                                    <div class="card-body">
                                        <p><a  [routerLink]="['/enterprise']">Trinka Enterprise solution</a> offers a variety of easy-to-integrate options. You may use Trinka JS to <b>integrate grammar check into your website by simply inserting a code snippet</b> or use Trinka API that lets you integrate Trinka into any of your existing products or workflows.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCTurnit">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCTurnitContent" aria-expanded="true"
                                        aria-controls="QuestionGCTurnitContent"><h4>Is the content detectable by Turnitin?</h4></a>
                                </div>
    
                                <div id="QuestionGCTurnitContent" class="collapse"
                                    aria-labelledby="QuestionGCTurnit" data-parent="#faq">
                                    <div class="card-body">
                                        <p>If there is plagiarized content in your text, it will be detected by Turnitin. Trinka does not write on your behalf, so any <a [routerLink]="['/features/plagiarism-check']">plagiarism</a> performed by you is your sole responsibility. You can, however, use Trinka’s <a [routerLink]="['/features/plagiarism-check/']">plagiarism checker</a> to avoid circulating plagiarized content.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCChatGPT">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCChatGPTContent" aria-expanded="true"
                                        aria-controls="QuestionGCChatGPTContent"><h4>Do you use ChatGPT/GPT-X?</h4></a>
                                </div>
    
                                <div id="QuestionGCChatGPTContent" class="collapse"
                                    aria-labelledby="QuestionGCChatGPT" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka suggests corrections to your writing through our proprietary AI technology and does not use ChatGPT/GPT-X to correct the text. Therefore, Trinka-corrected human writing should not be labelled as AI writing.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCFive">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCFiveContent" aria-expanded="true"
                                        aria-controls="QuestionGCFiveContent"><h4>How to check writing online for free?</h4></a>
                                </div>
    
                                <div id="QuestionGCFiveContent" class="collapse"
                                    aria-labelledby="QuestionGCFive" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Use Trinka to check your writing online for free. It is an advanced AI-powered tool that makes your writing clear and easy to understand. With Trinka, you can check your text for everything from minor punctuation errors and typos to nuanced grammatical mistakes and phrasing problems. You can check your grammar for free anywhere on the internet with Trinka's <a  [routerLink]="['/browser-plugin']">browser plugin</a> as well as in MS Word using the Trinka add-in for Word.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCSix">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCSixContent" aria-expanded="true"
                                        aria-controls="QuestionGCSixContent"><h4>How do I check if a sentence is grammatically correct?</h4></a>
                                </div>
    
                                <div id="QuestionGCSixContent" class="collapse"
                                    aria-labelledby="QuestionGCSix" data-parent="#faq">
                                    <div class="card-body">
                                        <p>In order to check the grammatical correctness of a sentence, simply paste it in Trinka's spelling and <strong>grammar checker</strong>. Trinka will thoroughly check the sentence for punctuation, grammar, and vocabulary mistakes.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCSeven">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCSevenContent" aria-expanded="true"
                                        aria-controls="QuestionGCSevenContent"><h4>How do you get spell check on Twitter?</h4></a>
                                </div>
    
                                <div id="QuestionGCSevenContent" class="collapse"
                                    aria-labelledby="QuestionGCSeven" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Just install Trinka's <a  [routerLink]="['/browser-plugin']">browser plug-in</a> for your favorite browser and let Trinka check the grammar of your tweet. Get instant <b>grammar check</b> across Twitter. </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCEight">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCEightContent" aria-expanded="true"
                                        aria-controls="QuestionGCEightContent"><h4>How do I check my grammar mistakes in word online?</h4></a>
                                </div>
    
                                <div id="QuestionGCEightContent" class="collapse"
                                    aria-labelledby="QuestionGCEight" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Install <a  [routerLink]="['/wordplugin']" class="text-link">Trinka's MS Word</a> add-in to get corrections in real time. Moreover, the<a href="/features/proofread-file" class="text-link">Trinka Proofread File</a> feature in the add-in can automatically edit your MS Word document in track changes, which you can then download from Word's side panel and finalize. Become a Premium member to use this powerful tech to make your writing clear and confident.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCNine">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCNineContent" aria-expanded="true"
                                        aria-controls="QuestionGCNineContent"><h4>How does the Trinka grammar checker actually work?</h4></a>
                                </div>
    
                                <div id="QuestionGCNineContent" class="collapse"
                                    aria-labelledby="QuestionGCNine" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka’s <b>grammar checker</b> uses advanced natural language processing (NLP) algorithms that are trained on millions of manuscripts in different subject areas. Trinka is also powered by handcrafted grammar rules that are continuously tested and updated to provide holistic content improvements.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCTen">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCTenContent" aria-expanded="true"
                                        aria-controls="QuestionGCTenContent"><h4>How do I check my Grammar on Google?</h4></a>
                                </div>
    
                                <div id="QuestionGCTenContent" class="collapse"
                                    aria-labelledby="QuestionGCTen" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Just install Trinka's <a  [routerLink]="['/browser-plugin']">browser plug-in</a> and get instant <b>grammar check</b> automatically on your favorite browsers. </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCeleven">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCelevenContent" aria-expanded="true"
                                        aria-controls="QuestionGCelevenContent"><h4>How accurate is the Trinka grammar checker?</h4> </a>
                                </div>
    
                                <div id="QuestionGCelevenContent" class="collapse"
                                    aria-labelledby="QuestionGCeleven" data-parent="#faq">
                                    <div class="card-body">
                                        <p>The Trinka <b>grammar checker</b> has been specifically designed for academic content. Hence, it is the best free grammar checker for academic and research content. Our assessments on relevant datasets show that Trinka generally achieves >90% precision and >80% recall.</p>
                                    </div>
                                </div>
                            </div>
                            

                            <div class="card">
                                <div class="card-header" id="QuestionGCTwelve">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCTwelveContent" aria-expanded="true"
                                        aria-controls="QuestionGCTwelveContent"><h4>Can Trinka check grammar for Word documents?</h4> </a>
                                </div>
    
                                <div id="QuestionGCTwelveContent" class="collapse"
                                    aria-labelledby="QuestionGCTwelve" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka's <a  [routerLink]="['/wordplugin']">MS Word add-in</a> checks and provides free instant <b>grammar checks</b>. Moreover, Trinka Proofread File automatically edits your MS Word document in track changes, which you can download and finalize.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCThirteen">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCThirteenContent" aria-expanded="true"
                                        aria-controls="QuestionGCThirteenContent"><h4>Can the Trinka free grammar checker correct sentence structure?</h4> </a>
                                </div>
    
                                <div id="QuestionGCThirteenContent" class="collapse"
                                    aria-labelledby="QuestionGCThirteen" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka’s free <b>grammar checker</b> goes beyond grammar checks and enhances your writing for sentence structure, style, tone, and much more.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCFouteen">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCFouteenContent" aria-expanded="true"
                                        aria-controls="QuestionGCFouteenContent"><h4>Can the Trinka spelling and grammar checker be accessible for free?</h4> </a>
                                </div>
    
                                <div id="QuestionGCFouteenContent" class="collapse"
                                    aria-labelledby="QuestionGCFouteen" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka spelling and <b>grammar checker </b>can be accessed freely on this page only up to 300 words, but with its basic plan you can use it up to 5000 words for free. </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCFivteen">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCFivteenContent" aria-expanded="true"
                                        aria-controls="QuestionGCFivteenContent"><h4>Can Trinka  be used as a grammar enhancer on my documents?</h4></a>
                                </div>
    
                                <div id="QuestionGCFivteenContent" class="collapse"
                                    aria-labelledby="QuestionGCFivteen" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka goes beyond grammar and spelling, so it will not just assist in enhancing the grammar of your document but also holistically improve the language quality of your document. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="faqContainer">

                            <div class="card">
                                <div class="card-header" id="QuestionGCSixteen">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCSixteenContent" aria-expanded="true"
                                        aria-controls="QuestionGCSixteenContent"><h4>Can Trinka's grammar checker online be used for manuscripts?</h4> </a>
                                </div>
    
                                <div id="QuestionGCSixteenContent" class="collapse"
                                    aria-labelledby="QuestionGCSixteen" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Because Trinka's <b>grammar checker</b> is specially designed for academic and formal/technical writing, it is the online best <b>grammar checker</b> for manuscripts.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCSeventeen">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCSeventeenContent" aria-expanded="true"
                                        aria-controls="QuestionGCSeventeenContent"><h4>Can Trinka's free grammar checker identify consistency errors?</h4></a>
                                </div>
    
                                <div id="QuestionGCSeventeenContent" class="collapse"
                                    aria-labelledby="QuestionGCSeventeen" data-parent="#faq">
                                    <div class="card-body">
                                        <p>In Trinka's free <b>grammar checker</b>, there is an inbuilt feature known as <a [routerLink]="['/features/consistency-check']">Consistency Checker</a>. It identifies 5 variations of inconsistencies in a single click.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCEighteen">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCEighteenContent" aria-expanded="true"
                                        aria-controls="QuestionGCEighteenContent"><h4>Can the Trinka grammar checker check MS word documents? </h4></a>
                                </div>
    
                                <div id="QuestionGCEighteenContent" class="collapse"
                                    aria-labelledby="QuestionGCEighteen" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka <b>grammar checker</b> offers an <a  [routerLink]="['/wordplugin']">add-in for MS word</a> users, Trinka also has an auto file edit feature where your document is automatically checked for <b>grammar errors</b> in seconds, and you get all the suggestions in track changes.  </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCNinteen">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCNinteenContent" aria-expanded="true"
                                        aria-controls="QuestionGCNinteenContent"><h4>Can the Trinka online free grammar checker be accessed offline? </h4></a>
                                </div>
    
                                <div id="QuestionGCNinteenContent" class="collapse"
                                    aria-labelledby="QuestionGCNinteen" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka's free <b>grammar checker</b> needs an active online connection to provide real-time suggestions. </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGC20">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGC20Content" aria-expanded="true"
                                        aria-controls="QuestionGC20Content"><h4>Where can I check if my grammar is correct?</h4> </a>
                                </div>
    
                                <div id="QuestionGC20Content" class="collapse"
                                    aria-labelledby="QuestionGC20" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka's free instant <b>grammar checker</b> will automatically proofread your text to ensure that it is in the most suitable form. You can click on the suggestions that appear for the underlined text to correct spelling mistakes and <b>grammar errors</b> or to apply style suggestions. </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGC21">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGC21Content" aria-expanded="true"
                                        aria-controls="QuestionGC21Content"><h4>Where can I check my grammar for free?</h4> </a>
                                </div>
    
                                <div id="QuestionGC21Content" class="collapse"
                                    aria-labelledby="QuestionGC21" data-parent="#faq">
                                    <div class="card-body">
                                        <p>With Trinka's Basic plan, you can <b>check your grammar</b> for free anywhere on the internet with Trinka's <a  [routerLink]="['/browser-plugin']" >browser plugin</a>.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGC22">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGC22Content" aria-expanded="true"
                                        aria-controls="QuestionGC22Content"><h4>Does the Trinka grammar checker provide grammar correction with explanation?</h4> </a>
                                </div>
    
                                <div id="QuestionGC22Content" class="collapse"
                                    aria-labelledby="QuestionGC22" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Absolutely! Trinka’s <b>grammar checker</b> provides suggestions with explanations for you to learn from your mistakes and make an informed change to your content.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGC23">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGC23Content" aria-expanded="true"
                                        aria-controls="QuestionGC23Content"><h4>Can the Trinka grammar checker replace proofreading?</h4> </a>
                                </div>
    
                                <div id="QuestionGC23Content" class="collapse"
                                    aria-labelledby="QuestionGC23" data-parent="#faq">
                                    <div class="card-body">
                                        <p>The quality of the original writing will determine this. A proofreader would be beneficial if you do not speak English and make several complex errors pertaining to logical construction in English. Trinka can fix thousands of grammatical mistakes and complex writing problems. However, AI is limited. If the construction is not logical, it cannot decipher your intended meaning. For those who possess sufficient knowledge of the English language to construct logically correct sentences, Trinka <b>grammar checker</b> can take care of tone, word choice, grammatical constructions, spelling, and a whole lot more.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGC24">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGC24Content" aria-expanded="true"
                                        aria-controls="QuestionGC24Content"><h4>Does the Trinka grammar checker check punctuation?</h4></a>
                                </div>
    
                                <div id="QuestionGC24Content" class="collapse"
                                    aria-labelledby="QuestionGC24" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Yes, Trinka’s <b>grammar checker</b> can correct punctuation and improve the quality of your content.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGC25">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGC25Content" aria-expanded="true"
                                        aria-controls="QuestionGC25Content"><h4>Does the Trinka Premium grammar checker have discounts for student users?</h4></a>
                                </div>
    
                                <div id="QuestionGC25Content" class="collapse"
                                    aria-labelledby="QuestionGC25" data-parent="#faq">
                                    <div class="card-body">
                                        <p>The Trinka Premium <b>grammar checker</b> can be accessed at a 20% discount by students. Reach out to <a href="mailto:support@trinka.ai">support@trinka.ai</a> to avail this discount. </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGC26">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGC26Content" aria-expanded="true"
                                        aria-controls="QuestionGC26Content"><h4>Does the Trinka Grammar Checker support languages other than English?</h4></a>
                                </div>
    
                                <div id="QuestionGC26Content" class="collapse"
                                    aria-labelledby="QuestionGC26" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka's <b>grammar checker</b> only supports English. </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGC27">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGC27Content" aria-expanded="true"
                                        aria-controls="QuestionGC27Content"><h4>On which websites does the Trinka Grammar Checker tool work?</h4></a>
                                </div>
    
                                <div id="QuestionGC27Content" class="collapse"
                                    aria-labelledby="QuestionGC27" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Use the Trinka <a  [routerLink]="['/browser-plugin']">browser plug-ins</a>, depending on your browser of choice, to take advantage of Trinka's free <b>grammar checker </b>across all websites.  </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGC28">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGC28Content" aria-expanded="true"
                                        aria-controls="QuestionGC28Content"><h4>Which applications are supported by the Trinka Grammar Checker tool?</h4> </a>
                                </div>
    
                                <div id="QuestionGC28Content" class="collapse"
                                    aria-labelledby="QuestionGC28" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Once you sign up for Trinka's Basic plan and download the Trinka <a  [routerLink]="['/browser-plugin']">browser plug-in</a>, you can access it across websites and <b>correct your grammar</b> anywhere, online. You can also use <a [routerLink]="['/wordplugin/']">Trinka Word add-in </a>for MS Word documents.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGC29">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGC29Content" aria-expanded="true"
                                        aria-controls="QuestionGC29Content"><h4>What kind of English mistakes can the Trinka Grammar Checker correct?</h4> </a>
                                </div>
    
                                <div id="QuestionGC29Content" class="collapse"
                                    aria-labelledby="QuestionGC29" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Besides spelling and grammar, Trinka's <b>grammar checker</b> also corrects punctuation, sentence structure, tone, etc. to improve your content.  </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGC30">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGC30Content" aria-expanded="true"
                                        aria-controls="QuestionGC30Content"><h4>Could the Trinka grammar checker be used to check the grammar in my thesis? </h4> </a>
                                </div>
    
                                <div id="QuestionGC30Content" class="collapse"
                                    aria-labelledby="QuestionGC30" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Yes. the Trinka <b>grammar checker</b> excels at revising academic/technical documents. We recommend that you use it for theses, research papers, reports, essays, and other formal documents.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGC31">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGC31Content" aria-expanded="true"
                                        aria-controls="QuestionGC31Content"><h4>Does Trinka AI support different languages?</h4> </a>
                                </div>
    
                                <div id="QuestionGC31Content" class="collapse"
                                    aria-labelledby="QuestionGC31" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Currently, Trinka AI does not support any other language except for English. However, we are continuously working on updates and improvements, and it is planned to be available in future updates. Stay tuned for further announcements regarding language support.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGC32">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGC32Content" aria-expanded="true"
                                        aria-controls="QuestionGC32Content"><h4>Can I integrate real-time checking into my text editor?</h4></a>
                                </div>
    
                                <div id="QuestionGC32Content" class="collapse"
                                    aria-labelledby="QuestionGC32" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Grammar checking has become an expected feature for any writers nowadays. Trinka made it easy to integrate free grammar checking feature into any online editor. We've build a grammar checking tool that makes this easy. You should be able to integrate grammar and spell checking into your editor in a few minutes. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>