<div class="trinka_cloud_wrap">
    <div id="particles-js"></div>
    <div class="cloud_top_banners">
        <!-- <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="trinka_cloud_banner_text">
                        <h1>Write Easily Anywhere With Trinka Cloud</h1>
                        <p>Access and edit your files securely with Trinka Cloud using your favorite browser. Write
                            better anytime, anywhere. </p>
                        <a id="TrinkaCloudGetStarted" href="https://cloud.trinka.ai/signup?_rg=GB" target="_blank">Get Started</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="trinka_cloud_banner_img">
                        <img src="../../../assets/images/cloud_banner_img.png" alt="cloud banner">
                    </div>
                </div>
            </div>
        </div> -->
        <section class="mainTopBanner">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="topBannerWrapper">
                            <div class="top-banner-left">
                                <h1>Cloud Editor</h1>
                                <h2>Write Easily Anywhere With Trinka Cloud</h2>
                                <p>Access and edit your files securely with Trinka Cloud using your favorite browser. Write
                                    better anytime, anywhere.
                                </p>
                                <div class="btn-group">
                                    <a id="TrinkaCloudGetStarted" class="filled-btn" href="https://cloud.trinka.ai/signup?_rg=GB" target="_blank">Get Started</a>
                                </div>
                            </div>
                            <div class="top-banner-right">
                                <img src="../../../assets/images/cloud_banner_img.png" alt="cloud banner">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <section class="section-how-it-works section-pd-sm">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10">
                    <div class="trinka-title text-center">
                        <h2>How It Works</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <ul>
                        <li>
                            <div class="login_circle_section">
                                <img src="../../../assets/images/RegisterAndLogin.png" alt="register">
                            </div>
                            <h3>Register / Login</h3>
                        </li>
                        <li>
                            <div class="arrow">
                                <img src="../../../assets/images/person-arrow.png" alt="arrow" class="img-fluid" />
                            </div>
                        </li>
                        <li>
                            <div class="login_circle_section">
                                <img src="../../../assets/images/TypeOrImportFile_icon.png" alt="import">
                            </div>
                            <h3>Type or import files</h3>
                        </li>
                        <li>
                            <div class="arrow">
                                <img src="../../../assets/images/person-arrow.png" alt="arrow" class="img-fluid" />
                            </div>
                        </li>
                        <li>
                            <div class="login_circle_section">
                                <img src="../../../assets/images/improveYourWriting_icon.png" alt="writing">
                            </div>
                            <h3>Improve your writing</h3>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <div class="cloud_key_features section-pd list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-12 col-lg-12">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Key Features of Trinka Cloud</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>Trinka Cloud lets you write anywhere you want. With real-time advanced English grammar corrections, Trinka is always with you. The Trinka web editor is feature-rich and saves your work automatically. Even better, you can import and export your documents too! Effective writing has never been so convenient!</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-none">
                        <img src="../../../assets/images/grammar-checker-icon.svg" alt="Grammar Checker" />
                        <h3>English Grammar Checker</h3>
                        <p>Get real-time writing suggestions and advanced grammar checks for your content wherever you write.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <img src="../../../assets/images/multilingual-icon.png" alt="Grammar Checker" />
                        <h3>Multilingual Grammar Check <div class="beta-badges">BETA</div></h3>
                        <p>Easily perfect your writing in Spanish and German with our advanced Grammar Checker. Trinka automatically detects the language and suggests improvements.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-none">
                        <img src="../../../assets/images/autoFileEdit_icon.svg" alt="auto file">
                        <h3>Proofread<br class="d-lg-block d-none"/> File</h3>
                        <p>Upload your MS Word document and sit back while Trinka auto-edits it in minutes. You can then
                            download and finalize it.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 dflex">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        
                        <img src="../../../assets/images/paraphraser-icon.svg" alt="Paraphraser">
                        <h3>Paraphraser <div class="beta-badges">New</div></h3>
                        <p><a [routerLink]="['/paraphrasing-tool']" class="text-link">Paraphrase</a> as you write with AI that understands your intent and offers suggestions to make your writing clear, coherent, and grammatically correct.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/ppc_icon.png" alt="ppc">
                        <h3>Plagiarism Check</h3>
                        <p>Experience the most advanced text similarity detection algorithm, iThenticate and the  largest paid publication database covering all scientific fields. </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/PublicationReadiness.svg" alt="publication readiness">
                        <h3>Technical Checks</h3>
                        <p>Evaluates on 20+ checkpoints, ranging from Journal Selection to Ethical Compliance, for publication success.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/ConsistencyCheck_icons.png" alt="consistency check">
                        <h3>Consistency Check</h3>
                        <p>Trinka's Consistency Check fixes inconsistencies that are hard-to-find, especially in a lengthy document.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/reference-quality.png" alt="reference quality">
                        <h3>Citation Checker</h3>
                        <p>Identifies citations that can make your research premise and argumentation weaker and helps you cite credible, strong citations for your paper.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/journalFinder.svg" alt="journal-finder">
                        <h3>Journal<br class="d-lg-block d-none"/> Finder</h3>
                        <p>Identifies the right journal by comparing the concepts in your paper against millions of publications and publication trends.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/style_guide_icon.svg" alt="style guide">
                        <h3>Style Guide Preferences</h3>
                        <p>Tailor the grammar & word choice of your manuscript to comply with popular academic style
                            guides (AMA 11th, AGU 2017).</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/AddtoDictionary.svg" alt="add to dictionary">
                        <h3>Add to Dictionary</h3>
                        <p>Add your own words to "My Dictionary" and Trinka will not flag them as spelling errors in
                            your future writing.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="importFiles section-pd-sm">
        <div class="trinka_container">
            <div class="row align-items-center">
                <div class="col-lg-5">
                    <div class="trinka-title mb-0">
                        <h3>Import Files from Your Favorite Cloud Storage and Get them Edited in Minutes!</h3>
                    </div>
                </div>
                <div class="col-lg-7">
                    <ul>
                        <li>
                            <div class="uploadFileIcon">
                                <svg width="52" height="46" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.25 0C3.65326 0 3.08097 0.237053 2.65901 0.65901C2.23705 1.08097 2 1.65326 2 2.25V11.25C2 11.8467 2.23705 12.419 2.65901 12.841C3.08097 13.2629 3.65326 13.5 4.25 13.5H19.75C20.3467 13.5 20.919 13.2629 21.341 12.841C21.7629 12.419 22 11.8467 22 11.25V2.25C22 1.65326 21.7629 1.08097 21.341 0.65901C20.919 0.237053 20.3467 0 19.75 0H4.25ZM3.5 2.25C3.5 2.05109 3.57902 1.86032 3.71967 1.71967C3.86032 1.57902 4.05109 1.5 4.25 1.5H19.75C19.9489 1.5 20.1397 1.57902 20.2803 1.71967C20.421 1.86032 20.5 2.05109 20.5 2.25V11.25C20.5 11.4489 20.421 11.6397 20.2803 11.7803C20.1397 11.921 19.9489 12 19.75 12H4.25C4.05109 12 3.86032 11.921 3.71967 11.7803C3.57902 11.6397 3.5 11.4489 3.5 11.25V2.25ZM0.75 14.5C0.551088 14.5 0.360322 14.579 0.21967 14.7197C0.0790175 14.8603 0 15.0511 0 15.25C0 15.4489 0.0790175 15.6397 0.21967 15.7803C0.360322 15.921 0.551088 16 0.75 16H23.25C23.4489 16 23.6397 15.921 23.7803 15.7803C23.921 15.6397 24 15.4489 24 15.25C24 15.0511 23.921 14.8603 23.7803 14.7197C23.6397 14.579 23.4489 14.5 23.25 14.5H0.75Z" fill="#4f4f4f"/>
                                    <path d="M10 6H14" stroke="#773795" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <p>My Device</p>
                        </li>
                        <li>
                            <div class="uploadFileIcon">
                                <svg width="51" height="47" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_1393_8871)">
                                    <path d="M5.99953 0L0 3.82228L5.99953 7.64466L12 3.82228L5.99953 0ZM18 0L12 3.82266L18 7.64531L24.0001 3.82266L18 0ZM0 11.4676L5.99953 15.29L12 11.4676L5.99953 7.64531L0 11.4676ZM18 7.64531L12 11.468L18 15.2905L24 11.468L18 7.64531ZM6 16.5723L12.0005 20.3947L18 16.5723L12.0005 12.75L6 16.5723Z" fill="#0061FF"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_1393_8871">
                                    <rect width="24" height="20.4375" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <p >DropBox</p>
                        </li>
                        <li>
                            <div class="uploadFileIcon">
                                <svg width="50" height="46" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.81444 18.3783L2.87287 20.2064C3.09281 20.5914 3.40894 20.8938 3.78009 21.1137C4.84322 19.7643 5.58309 18.7287 6.00009 18.0071C6.42319 17.2749 6.94322 16.1293 7.56019 14.5707C5.89744 14.3518 4.63744 14.2424 3.78019 14.2424C2.95734 14.2424 1.69734 14.3518 0 14.5707C0 14.9968 0.109969 15.4229 0.329906 15.8079L1.81444 18.3783V18.3783Z" fill="#0066DA"/>
                                    <path d="M20.2204 21.1137C20.5917 20.8938 20.9078 20.5914 21.1276 20.2065L21.5675 19.4505L23.6707 15.8079C23.8866 15.4313 24.0003 15.0048 24.0005 14.5707C22.2933 14.3518 21.0357 14.2424 20.2274 14.2424C19.3587 14.2424 18.1009 14.3518 16.4541 14.5707C17.0638 16.1379 17.5769 17.2834 17.9937 18.0071C18.4139 18.7374 19.1562 19.7728 20.2204 21.1137V21.1137Z" fill="#EA4335"/>
                                    <path d="M11.9998 6.87291C13.2298 5.38744 14.0775 4.24191 14.5428 3.4365C14.9175 2.78794 15.3299 1.75238 15.7799 0.329906C15.4088 0.109969 14.9827 0 14.5428 0H9.45685C9.01698 0 8.59098 0.12375 8.21973 0.329906C8.79216 1.96134 9.27798 3.12244 9.67698 3.81309C10.118 4.57641 10.8923 5.59631 11.9998 6.87291Z" fill="#00832D"/>
                                    <path d="M16.4401 14.5706H7.56028L3.78027 21.1136C4.15134 21.3335 4.57743 21.4435 5.01731 21.4435H18.9831C19.4229 21.4435 19.8491 21.3197 20.2202 21.1135L16.4402 14.5706H16.4401Z" fill="#2684FC"/>
                                    <path d="M12.0001 6.87298L8.22009 0.330078C7.84884 0.550016 7.53272 0.852359 7.31278 1.2373L0.329906 13.3336C0.114012 13.7102 0.000285232 14.1366 0 14.5707H7.56019L12.0002 6.87298H12.0001Z" fill="#00AC47"/>
                                    <path d="M20.1788 7.28524L16.6872 1.23705C16.4674 0.852115 16.1512 0.549772 15.78 0.329834L12 6.87284L16.4398 14.5706H23.9863C23.9863 14.1444 23.8763 13.7184 23.6564 13.3334L20.1788 7.28524Z" fill="#FFBA00"/>
                                </svg>
                            </div>
                            <p >Google Drive</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="otherApps section-pd-sm">
        <div class="trinka_container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="trinka-title text-center">
                        <h3 class="text-white">Use Trinka in Other Apps</h3>
                    </div>
                    <ul>
                        <li>
                            <a  [routerLink]="['/wordplugin']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Others_App_MSWord.svg" alt="icon">
                                <h3>MS Word Add-in</h3>
                            </a>
                        </li>
                        <li>
                            <a  [routerLink]="['/browser-plugin']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/other_Apps_browser.svg" alt="icon">
                                <h3>Browser Plug-in</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/enterprise']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Other_Apps_enterprise.svg" alt="icon">
                                <h3>Enterprise <small>(API, JS, & On-Premise)</small></h3>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>