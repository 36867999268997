import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WebsiteService {

  constructor(private http: HttpClient) { }

  postSubscribe(email: string): Observable<any> {
    return this.http.post<any>(environment.server_address + "/support/api/v1/subscription", { "email_id": email });
  }

  postContactUs(email, query, pageURL): Observable<any> {
    return this.http.post<any>(environment.server_address + "/support/api/v1/enquiry", { "email_id": email, "query": query, "page_url":  pageURL});
  }

  postContactSales(email: string): Observable<any> {
    return this.http.post<any>(environment.server_address + "/support/api/v1/enquiry", { "email_id": email, "query": "sales" });
  }

  getUserDetails(user_id: any) {
    return this.http.get<any>(environment.server_address + "/trinka/api/v1/user/" + user_id);
  }

  getPhraseBankSearch(requestBody: object): Observable<any> {
    return this.http.post<any>(`${environment.server_url.phrasebank_server}/phraseClassifier`, requestBody);
  }

  getPhraseBankTopics(): Observable<any> {
    return this.http.get<any>(`${environment.server_url.phrasebank_server}/get_hierarchy`);
  }

  getPhraseBankPhrases(requestBody: object): Observable<any> {
    return this.http.post<any>(`${environment.server_url.phrasebank_server}/get_phrases_by_category`, requestBody);
  }

  checkCall(credential, ssoURL){
    const headers= new HttpHeaders()
    .set('Content-type', 'application/json')
    .set('Access-Control-Allow-Origin', 'Content-type')
    .set('Accept', 'application/json')
    .set('credentials', credential);
    console.log(ssoURL)
    console.log(credential)
    return this.http.get(ssoURL,{headers});
  }

  getPlans(user_id : any,plan_type : any){
    return this.http.get(environment.getCountryCode + "/api/v1/payment/get/plans/JTdCJTIydXNlcklkJTIyJTNBJTIyJTIyJTJDJTIycGxhblR5cGUlMjIlM0ElMjJwcmVtaXVtJTIwcGx1cyUyMiUyQyUyMmlzQW5udWFsUGxhbiUyMiUzQTAlMkMlMjJnb190b19wYXltZW50cyUyMiUzQXRydWUlMkMlMjJmcm1rdGclMjIlM0F0cnVlJTdE/"+plan_type + "/"+environment.production+"/"+localStorage.getItem("country-trinka")+"/false");
  }

  premiumGetPlan(user_id : any,plan_type : any){
    return this.http.get(environment.getCountryCode + "/api/v1/payment/get/plans/JTdCJTIyaXNfcHJlbWl1bSUyMiUzQXRydWUlN0Q=/"+plan_type + "/"+environment.production+"/"+localStorage.getItem("country-trinka")+"/false");
  }

  sendToParaphraseRequest(data: any) {
    return this.http.post(`${environment.server_url.paraphraser_server}/paraphraser/marketing/paragraphs`, data);
  }

  paraphraseFeedbackRequest(data: any) {
    return this.http.post(`${environment.server_url.paraphraser_server}/feedback/sentence`, data);
  }

  submitFeedbackCall(feature: string = 'paraphraser', payload: any, meta: any):Observable<any>{
    let endpoint = '';
    switch (feature) {
      case 'paraphraser': {
        endpoint = `${environment.server_url.paraphraser_server}/feedback/modal`;
        break;
      }

      case 'multilingual': {
        endpoint = `${environment.server_url.multilingual_server}/api/gec/feedback`;
        const today = new Date();
        const dateKey = `${today.getDate()}${today.getMonth() + 1}${today.getFullYear()}`
        let _userId = localStorage.getItem(dateKey) || '';
        payload.source = `trinka-mktg-${meta.language}`
        payload.uniqueId = _userId
        break;
      }

      default:
      break;
    }

    return this.http.post<any>(endpoint, payload);
  }

  aiDetectionRequest(data: any, userId: string) {
    return this.http.post(`${environment.server_url.ai_detection_server}/api/ai/content`, data,  {
      headers: new HttpHeaders().set('user-id', userId),
      observe: 'response'
    });
  }

  submitAIFeedbackCall(payload: any):Observable<any>{
    return this.http.post<any>(`${environment.server_url.ai_detection_server}/api/save-feedback`, payload);
  }

  getAIDetectionSessionCount(sessionId: any):Observable<any>{
    return this.http.get<any>(`${environment.server_url.ai_detection_server}/api/get-session-count/${sessionId}`);
  }

  registerForWebinarRequest(token, formData: any): Observable<any>{
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(`${environment.webinar.server_endpoint}/events-formdata`, formData, { headers });
  }

  getAuthTokenForWebinarRequest(): Observable<any>{
    return this.http.post<any[]>(`${environment.webinar.server_endpoint}/auth/local`, {
      "identifier": environment.webinar.auth.identifier,
      "password": environment.webinar.auth.password
    });
  }

  sendEmailForWebinarRequest(data: any): Observable<any>{
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const url = `${environment.webinar.server_endpoint_email}/academy/trinka-zoom-api/index.php`;
    const httpRequest = new HttpRequest('POST', url, JSON.stringify(data), {
      headers: headers,
      responseType: 'text'
    });

    return this.http.request(httpRequest);
  }
}
